import styled from "styled-components";

export const InProcess = styled.span`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > i {
    font-size: 21px;
    color: ${(props) => props.theme.colors[9]};
  }
`;

export const Message = styled.div`
  background: ${(props) => props.theme.colors[0]};
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: -350px;
  z-index: 100;
  display: ${(props) => (props.show ? "block" : "none")};
  width: 320px;
  padding: 15px;
  box-shadow: 2px 2px 2px 2px ${(props) => props.theme.colors[13]};
  h2 {
    font-size: 16px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    width: 80vw;
    left: -80vw;
    top: -206px;
  }
`;
