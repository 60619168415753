import React, { useState, useEffect } from "react";
import Styled from "./PayPopup.styles";
import l10n from "../../localization";
import Modal from "../Modal";
import GatewayWspayForm from "../GatewayWSPAYForm";
import { Row, Col } from "reactstrap";
import Helpers from "../../helpers/helpers";
import { getPayAllSignature } from "../../redux/actions/payAll.actions";
import { connect } from "react-redux";

import maestro from "../../assets/maestro50.gif";
import mastercard from "../../assets/MasterCard50.gif";
import verifiedvisa from "../../assets/visa-secure.png";
import visa from "../../assets/Visa50.gif";
import wspaylogo from "../../assets/wsPayWebSecureLogo-118x50-transparent.png";
import logo from "../../assets/logo-vodovod-labin.svg";
import mastercardsecure from "../../assets/mastercard-identity-check.png";
import { IconPayBill } from "../../components/Icons/Icons";

const PayPopup = ({ buttonLabel, invoices, ...props }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [paymentDisabled, setPaymentDisabled] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [invoicesSum, setInvoicesSum] = useState(0);
  const [all, setAll] = useState(false);

  useEffect(() => {
    if (popupOpen) {
      let debtValue = 0;

      if (props.user && props.user.userData && props.user.userData.data) {
        debtValue = props.user.userData.data.waterServicesDebt || 0;
      }

      if (props.payAllInvoices) {
        setAll(true);
        setInvoice({
          invoiceId: props.payAll.data.shoppingCartId,
          invoiceValue: debtValue,
          isPayed: "Open",
          wsPaySignature: props.payAll.data.wsPaySignature,
        });
      } else {
        setAll(false);
        setInvoice({
          invoiceId: invoices[0].billReference,
          invoiceValue: invoices[0].invoiceDebt,
          isPayed: invoices[0].isPayed,
          wsPaySignature: invoices[0].wsPaySignature,
        });

        debtValue = invoices[0].invoiceDebt;
      }

      setInvoicesSum(debtValue);
    }
    //eslint-disable-next-line
  }, [popupOpen]);

  const openPopup = (e) => {
    e.stopPropagation();
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const acceptConditions = (e) => {
    setPaymentDisabled(!e.target.checked);
  };

  return (
    <>
      {popupOpen && (
        <Modal
          open={popupOpen}
          title={l10n.payPopup.modalTitle}
          footer={true}
          closeButtonText={l10n.payPopup.closeButton}
          onClose={closePopup}
        >
          {process.env.REACT_APP_PAYMENT === "0" && (
            <Row>
              <Col>
                <strong>
                  Poštovani korisnici aplikacije, izravno plaćanje računa
                  trenutno nije moguće.
                  <br />
                  Usluga će biti dostupna u najkraćem roku, o čemu ćemo Vas
                  izvjestiti porukom.
                </strong>
              </Col>
            </Row>
          )}
          {process.env.REACT_APP_PAYMENT === "1" && (
            <>
              {!props.payAllInvoices && (
                <>
                  <Styled.ItemLine>
                    <Col>{l10n.payPopup.invoiceNumber}</Col>
                    <Col align="right">{l10n.payPopup.total}</Col>
                  </Styled.ItemLine>
                  {invoices.map((invoiceRow) => (
                    <Styled.ItemLine key={invoiceRow.invoiceId}>
                      <Col>{invoiceRow.invoiceId}</Col>
                      <Col align="right">
                        {Helpers.formatPrice(invoiceRow.invoiceDebt)}
                      </Col>
                    </Styled.ItemLine>
                  ))}
                </>
              )}

              <Row>
                <Col />
                <Col align="right">
                  {l10n.payPopup.total2}:{" "}
                  <strong>{Helpers.formatPrice(invoicesSum)}</strong>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Styled.checkbox
                    type="checkbox"
                    name="termsAndConditions"
                    id="termsAndConditions"
                    onClick={acceptConditions}
                  />

                  <label htmlFor="termsAndConditions">
                    {l10n.payPopup.iAgree}{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {l10n.payPopup.terms}
                    </a>
                  </label>
                </Col>
              </Row>

              <GatewayWspayForm
                loading={props.payAll.loading}
                invoice={invoice}
                disabled={paymentDisabled}
                all={all}
              />

              <div
                dangerouslySetInnerHTML={{ __html: l10n.payPopup.withClick }}
              />
              <Row>
                <Col>
                  <Styled.CardHolder>
                    <a
                      href="https://www.visa.com.hr/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={visa} alt="visa" />
                    </a>
                    <a
                      href="https://www.mastercard.com/hr/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={mastercard} alt="mastercard" />
                    </a>
                    <a
                      href="https://www.maestrocard.com/hr/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={maestro} alt="maestro" />
                    </a>
                    <a
                      href="https://www.wspay.info/mastercard-securecode.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={mastercardsecure} alt="mastercard secure" />
                    </a>
                    <a
                      href="https://www.wspay.info/verified-by-visa.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={verifiedvisa} alt="visa" />
                    </a>
                    <a
                      href="https://www.wspay.info"
                      title="WSpay - Web Secure Payment Gateway"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="WSpay - Web Secure Payment Gateway"
                        src={wspaylogo}
                        border="0"
                      />
                    </a>
                  </Styled.CardHolder>
                </Col>
              </Row>
              <p>
                {l10n.payPopup.agree}{" "}
                <a
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {l10n.payPopup.agreeTerms}
                </a>{" "}
                {l10n.payPopup.company}.
              </p>
              <Styled.Title>{l10n.payPopup.title1}</Styled.Title>
              <Row>
                <Col>
                  <p>
                    <b>{l10n.payPopup.subtitle1}</b>
                  </p>
                  <Styled.Paragraph>
                    {l10n.payPopup.companyName}
                    {" ("}
                    <a href={`https://${l10n.payPopup.companyLink}`}>
                      {l10n.payPopup.companyLink}
                    </a>
                    {")"}
                  </Styled.Paragraph>
                  <Styled.Paragraph>
                    {l10n.payPopup.companyAddress}
                  </Styled.Paragraph>
                  <Styled.Paragraph>
                    {l10n.payPopup.companyOib}
                  </Styled.Paragraph>
                  <Styled.Paragraph>
                    {l10n.payPopup.companyIban}
                  </Styled.Paragraph>
                  <Styled.Paragraph>
                    {l10n.payPopup.companyEmail}{" "}
                    <a href={`mailto:${l10n.payPopup.companyEmailAddress}`}>
                      {l10n.payPopup.companyEmailAddress}
                    </a>
                  </Styled.Paragraph>
                  <Styled.Paragraph>
                    {l10n.payPopup.companyTelephone}
                  </Styled.Paragraph>
                </Col>
                <Col className="text-center">
                  <Styled.logo src={logo} alt="vodovod labin" />
                </Col>
              </Row>

              <br />
              <p>
                <b>{l10n.payPopup.paymentOptions}</b>
              </p>
              <Styled.Paragraph>
                {l10n.payPopup.paymentDescription}
              </Styled.Paragraph>
              <Styled.Paragraph>
                {l10n.payPopup.paymentDescription2}
              </Styled.Paragraph>
              <Styled.Paragraph>
                {l10n.payPopup.paymentDescription3}
              </Styled.Paragraph>

              <br />
              <p>
                <b>{l10n.payPopup.statement}</b>
              </p>
              <p>{l10n.payPopup.statementDescription}</p>

              <p>
                <b>{l10n.payPopup.statement2}</b>
              </p>
              <p>{l10n.payPopup.statementDescription2}</p>

              <p>
                <b>{l10n.payPopup.statement3}</b>
              </p>
              <p>{l10n.payPopup.statementDescription3}</p>
            </>
          )}
        </Modal>
      )}
      {props.payAllInvoices ? (
        <Styled.button onClick={openPopup} color="success">
          Platite sve otvorene račune
        </Styled.button>
      ) : (
        <Styled.button onClick={openPopup} color="success">
          <IconPayBill></IconPayBill>
        </Styled.button>
      )}
    </>
  );
};
export default connect(({ payAll, user }) => ({ payAll, user }), {
  getPayAllSignature,
})(PayPopup);
