import activateUser from "./activateUser.sagas";
import resendUserActivationMail from "./resendUserActivationMail.sagas";
import changePassword from "./changePassword.sagas";
import passwordRecovery from "./passwordRecovery.sagas";
import resetPassword from "./resetPassword.sagas";
import registerUser from "./registerUser.sagas";
import sendEnquiry from "./sendEnquiry.sagas";
import userProfile from "./userProfile.sagas";
import user from "./user.sagas";

export default [
  ...activateUser,
  ...resendUserActivationMail,
  ...changePassword,
  ...passwordRecovery,
  ...resetPassword,
  ...registerUser,
  ...sendEnquiry,
  ...userProfile,
  ...user
];
