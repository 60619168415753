import { Types } from "../actions/eInvoice.actions";

const INITIAL_STATE = {
  onlineInvoiceLoading: false,
  onlineNotificationLoading: false,
  emailInvoiceLoading: false,
  onlineInvoiceError: null,
  onlineNotificationError: null,
  emailInvoiceError: null,
};

export default function eInvoiceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ONLINE_INVOICE:
      return {
        ...state,
        onlineInvoiceLoading: true,
        onlineInvoiceError: null
      };

    case Types.ONLINE_INVOICE_SUCCESS:
      return {
        ...state,
        onlineInvoiceLoading: false,
        onlineInvoiceError: null
      };

    case Types.ONLINE_INVOICE_ERROR:
      return {
        ...state,
        onlineInvoiceLoading: false,
        onlineInvoiceError: action.payload
      };

    case Types.ONLINE_NOTIFICATION:
      return {
        ...state,
        onlineNotificationLoading: true,
        onlineNotificationError: null
      };

    case Types.ONLINE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        onlineNotificationLoading: false,
        onlineNotificationError: null
      };

    case Types.ONLINE_NOTIFICATION_ERROR:
      return {
        ...state,
        onlineNotificationLoading: false,
        onlineNotificationError: action.payload
      };

      case Types.EMAIL_INVOICE:
        return {
          ...state,
          emailInvoiceLoading: true,
          emailInvoiceError: null
        };

      case Types.EMAIL_INVOICE_SUCCESS:
        return {
          ...state,
          onlineInvoiceLoading: false,
          onlineInvoiceError: null
        };

      case Types.EMAIL_INVOICE_ERROR:
        return {
          ...state,
          emailInvoiceLoading: false,
          emailInvoiceError: action.payload
        };

    default:
      return state;
  }
}
