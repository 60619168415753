import styled from "styled-components";

export const DownloadLink = styled.span`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > i {
    font-size: 21px;
    color: ${props => props.theme.colors[15]};
  }
`;
