import { call, fork, takeLatest, put, select } from "redux-saga/effects";
import { getPayAllSignature } from "../actions/payAll.actions";
import { logoutUser } from "../actions/user/user.actions";
import * as actions from "../actions/invoice.actions";
import * as api from "../api/invoice.api";

function* getInvoices({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.getInvoices,
      state.user.userData.data.userId,
      payload
    );
    yield put(actions.getInvoicesSuccess(response.data));
    yield put(
      getPayAllSignature({
        invoiceIds: response.data
          .filter((invoice) => invoice.isPayed === "Open")
          .map((invoice) => invoice.invoiceId),
      })
    );
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.invoiceError({
        error: "An error occurred when trying to get invoices",
      })
    );
  }
}

function* watchGetInvoicesRequest() {
  yield takeLatest(actions.Types.GET_INVOICES, getInvoices);
}

function* getInvoicePdf({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.getInvoicePdf,
      state.user.userData.data.userId,
      payload.invoiceId
    );

    const file = new Blob([response.data], { type: "application/pdf" });
    const pdfURL = window.URL.createObjectURL(file);
    let fileLink = document.createElement("a");
    fileLink.href = pdfURL;
    fileLink.download = `Invoice-${payload.invoiceId}.pdf`;
    document.body.appendChild(fileLink);
    fileLink.click();

    setTimeout(() => {
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(pdfURL);
    }, 100);

    yield put(actions.getInvoicePdfSuccess(payload.invoiceId));
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.getInvoicePdfError(
        payload.invoiceId,
        "An error occurred when trying to get invoice PDF"
      )
    );
  }
}

function* watchGetInvoicePDFRequest() {
  yield takeLatest(actions.Types.GET_INVOICE_PDF, getInvoicePdf);
}

function* markInvoicePayed({ payload }) {
  const state = yield select();
  try {
    yield call(
      api.markInvoiceAsPayed,
      state.user.userData.data.userId,
      payload.ShoppingCartId,
      payload.WsPayOrderId
    );
    yield call(getInvoices);
  } catch (err) {
    console.log("There was a problem", err);
  }
}

function* watchMarkInvoicePayed() {
  yield takeLatest(actions.Types.MARK_INVOICE_PAYED, markInvoicePayed);
}

function* payAllOnline({ payload }) {
  const state = yield select();
  try {
    yield call(
      api.payAllOnline,
      state.user.userData.data.userId,
      payload.ShoppingCartId,
      payload.WsPayOrderId
    );
    yield call(getInvoices);
  } catch (err) {
    console.log("There was a problem", err);
  }
}

function* watchPayAllOnline() {
  yield takeLatest(actions.Types.PAY_ALL_ONLINE, payAllOnline);
}

export default [
  fork(watchGetInvoicesRequest),
  fork(watchGetInvoicePDFRequest),
  fork(watchMarkInvoicePayed),
  fork(watchPayAllOnline),
];
