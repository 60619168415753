import { call, fork, put, takeLatest, select } from "redux-saga/effects";
import * as actions from "../actions/document.actions";
import * as api from "../api/documents.api";

function* getDocumentTypes() {
  try {
    const response = yield call(api.getDocumentTypes);
    yield put(actions.getDocumentTypesSuccess(response.data));
  } catch (err) {
    yield put(actions.getDocumentTypesError(err.response.data.Messages));
  }
}

function* getDocumentPDF({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.getDocumentPdf,
      state.user.userData.data.userId,
      payload
    );

    const fileName = payload.documentName.replaceAll(" ", "_");
    const file = new Blob([response.data], { type: "application/pdf" });
    const pdfURL = window.URL.createObjectURL(file);
    let fileLink = document.createElement("a");
    fileLink.href = pdfURL;
    fileLink.download = `${fileName}.pdf`;
    document.body.appendChild(fileLink);
    fileLink.click();

    setTimeout(() => {
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(pdfURL);
    }, 100);
    yield put(actions.getDocumentPDFSuccess(response.data));
  } catch (err) {
    yield put(actions.getDocumentPDFError(err.response.data.Messages));
  }
}

function* getDocumentPDFFieldsUpdated({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.getDocumentPdfFieldsUpdated,
      state.user.userData.data.userId,
      payload
    );
    yield put(actions.getDocumentPDFFieldsUpdatedSuccess(response.data));
  } catch (err) {
    yield put(
      actions.getDocumentPDFFieldsUpdatedError(err.response.data.Messages)
    );
  }
}

function* getDocumentPDFFields({ payload }) {
  try {
    const state = yield select();
    const response = yield call(
      api.getDocumentPdfFields,
      state.user.userData.data.userId,
      payload
    );
    yield put(actions.getDocumentPDFFieldsSuccess(response.data));
  } catch (err) {
    yield put(actions.getDocumentPDFFieldsError(err.response.data.Messages));
  }
}

function* watchGetDocumentTypes() {
  yield takeLatest(actions.Types.GET_DOCUMENT_TYPES, getDocumentTypes);
}

function* watchGetDocumentPDF() {
  yield takeLatest(actions.Types.GET_DOCUMENT_PDF, getDocumentPDF);
}
function* watchGetDocumentPDFFields() {
  yield takeLatest(actions.Types.GET_DOCUMENT_PDF_FIELDS, getDocumentPDFFields);
}

function* watchGetDocumentPDFFieldsUpdated() {
  yield takeLatest(
    actions.Types.GET_DOCUMENT_PDF_FIELDS_UPDATED,
    getDocumentPDFFieldsUpdated
  );
}

export default [
  fork(watchGetDocumentTypes),
  fork(watchGetDocumentPDF),
  fork(watchGetDocumentPDFFields),
  fork(watchGetDocumentPDFFieldsUpdated),
];
