import React from "react";
import moment from "moment";
import l10n from "../../../../../../localization";
import Helpers from "../../../../../../helpers/helpers";
import * as Styled from "./mobileMeterRow.styles";

const MobileMeterRow = ({ reading }) => {
  return (
    <Styled.Wrapper>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>{l10n.reports.date}</Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {moment(reading.readingDate).format("DD.MM.YYYY")}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>{l10n.reports.reading}</Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>{reading.reading}</Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.reports.consumption}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {Helpers.formatNumber(reading.reading)}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.reports.deducedConsumption}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {Helpers.formatNumber(reading.deductedConsumption)}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.reports.addedConsumption}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {Helpers.formatNumber(reading.addedConsumption)}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.reports.accountedConsumption}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {Helpers.formatNumber(reading.accountedConsumption)}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      {(reading.isIncrConsumption || reading.isIncrConsumptionMainMeter) && (
        <Styled.MeterInvoiceRow>
          <Styled.MeterInvoiceKey>{l10n.reports.status}</Styled.MeterInvoiceKey>
          <Styled.MeterInvoiceValue>
            {reading.isIncrConsumptionMain
              ? l10n.meterRow.increasedConsumptionMain
              : l10n.meterRow.increasedConsumption}
          </Styled.MeterInvoiceValue>
        </Styled.MeterInvoiceRow>
      )}
    </Styled.Wrapper>
  );
};

export default MobileMeterRow;
