import styled from "styled-components";

export default {
  Card: styled.div`
    background: ${(props) => props.theme.colors[0]};
    box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
    border-radius: 4px;
    padding: 0;
    margin: 0 0 25px 0;
    overflow-y: auto;
  `,
  Table: styled.table`
    max-width: 100%;
    width: 100%;

    tr:not(:last-of-type) {
      td {
        border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      }
    }

    th {
      padding: 15px;
      border-bottom: 1px solid ${(props) => props.theme.colors[17]};

      &:first-of-type {
        padding-left: 24px;
      }
      &:last-of-type {
        padding-right: 24px;
      }
    }

    td {
      font-size: 13px;
      line-height: 19px;
      padding: 10px 15px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[22]};

      &:first-of-type {
        padding-left: 24px;
      }

      &:not(:first-of-type) {
      }

      &:last-of-type {
        padding-right: 24px;
        text-align: left;
      }
    }

    th {
      font-weight: 500;
      padding: 15px;
      color: ${(props) => props.theme.colors[18]};
      border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      opacity: 0.5;

      &:last-of-type {
        width: 100%;
      }
    }
  `,
  HeaderWrapper: styled.div`
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `,
  Header: styled.div`
    padding: 16px 24px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 15px 15px 0;
    }
  `,
  HeaderRow: styled.div`
    display: flex;
    flex-wrap: nowrap;
  `,
  Meter: styled.div`
    padding: 0 24px 20px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 0 15px 15px;
    }
  `,
  HeaderTitle: styled.span`
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[18]};
    opacity: 0.5;
    min-width: 120px;
    display: inline-block;
  `,
  HeaderContent: styled.span`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[23]};
  `,
  CardMobileTable: styled.div`
    padding: 15px;

    &:empty {
      padding: 0;
    }
  `,
  MeterData: styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 16px 24px;

    @media (max-width: 1024px) {
      padding: 16px;
    }
  `,
  MeterDataItem: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-basis: 33%;
    padding: 5px 0;
  `,
  MeterDataKey: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[18]};
    opacity: 0.5;
    min-width: 120px;
    display: inline-block;
    margin: 0;
    padding: 0;
  `,
  MeterDataValue: styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[23]};
    margin: 0;
    padding: 0;
  `,
  Pagination: styled.div`
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    margin: 0 25px;
    padding-top: 10px;

    .pagination {
      //border: 1px solid #aaa;
      margin: 0;
      padding: 0;

      .active {
        background-color: ${(props) => props.theme.colors[19]};
        color: white;
        font-weight: bold;
      }

      li {
        border-radius: 17px;
        color: #bbb;
        margin: 0 5px;
        user-select: none;
        transition: background-color 0.2s ease-in, color 0.2s ease-in;

        &:hover {
          background-color: ${(props) => props.theme.colors[19]};
          border-radius: 17px;
          color: white;
          cursor: pointer;
        }

        a {
          align-items: center;
          display: flex;
          justify-content: center;
          outline: none;
          height: 35px;
          width: 35px;
        }
      }
    }
  `,
};
