import React from "react";
import * as Styled from "./Header.styles";
import HeaderNotification from "./_components/HeaderNotification";
import HeaderProfile from "./_components/HeaderProfile/HeaderProfile";
import HeaderLink from "./_components/HeaderLink/HeaderLink";
import Logo from "../Logo/Logo";

function Header(props) {
  return (
    <Styled.Header>
      <Styled.MobileHeaderLogo to="/">
        <Logo />
      </Styled.MobileHeaderLogo>

      <Styled.HeaderPart>
        <i className="fas fa-bars" onClick={props.onMobileMenuChange} />
      </Styled.HeaderPart>

      <Styled.RightNavigation>
        <HeaderLink />
        <Styled.HeaderSeparator />
        <HeaderNotification />
        <HeaderProfile />
      </Styled.RightNavigation>
    </Styled.Header>
  );
}

export default Header;
