export const Types = {
  GET_2D_CODE: "2dCode/get_2d_code",
  GET_2D_CODE_SUCCESS: "2dCode/get_2d_code_success",
  GET_2D_CODE_ERROR: "2dCode/get_2d_code_error",
  GET_2D_CODE_RESET: "2dCode/get_2d_code_reset"
};

export const get2dCode = invoiceId => ({
  type: Types.GET_2D_CODE,
  payload: { invoiceId }
});

export const get2dCodeSuccess = response => ({
  type: Types.GET_2D_CODE_SUCCESS,
  payload: response
});

export const get2dCodeError = payload => ({
  type: Types.GET_2D_CODE_ERROR,
  payload
});

export const get2dCodeReset = () => ({
  type: Types.GET_2D_CODE_RESET
});
