import styled from "styled-components";
import { PDFGreenIcon } from "../../components/Icons/Icons";

export const Header = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 30px;
`;

export const HeaderLeft = styled.div`
  flex-grow: 1;
`;

export const HeaderRight = styled.div`
  display: ${(props) => (props.isMobile ? "none" : "block")};
  button {
    padding: 10px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: ${(props) => props.theme.colors[0]};
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;
    margin-right: 5px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }
  }
`;

export const Title2 = styled.p`
  color: #323c47;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
`;

export const SubtitleView = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const Subtitle = styled.p`
  color: #323c47;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 18px;
  margin: 0;
  padding: 0;
`;

export const IconWrapper = styled.div`
  padding: 0 5px;
`;

export const PDFIcon = styled(PDFGreenIcon).attrs({})`
  margin-left: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[23]};
  margin: 0 0 25px;
`;

export const Description = styled.span`
  color: ${(props) => props.theme.colors[27]};
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0 0 20px;
  padding: 0;
`;

export const DescriptionText = styled.p`
  margin: 0;
  margin-right: 5px;
  padding: 0;
`;

export const Card = styled.div`
  margin: 0 0 20px 0;
  background-color: ${(props) => props.theme.colors[0]};
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};

  table {
    th {
      border-top: none;
      border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      padding: 20px;
      padding-left: 0;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[18]};
      vertical-align: middle;

      &:first-of-type {
        padding-left: 24px;
      }

      &:last-of-type {
        padding-left: 0;
      }
    }

    td {
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[22]};
      padding-left: 0;

      &:first-of-type {
        padding-left: 22px;
      }
    }

    thead {
    }

    tbody {
      tr:not(:last-of-type) {
        td {
          border-bottom: 1px solid ${(props) => props.theme.colors[17]};
        }
      }
    }
  }
`;

export const RowedFields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

export const WrappedInputAndLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: between;
  margin: 5px;
`;

export const RadioRows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: between;
`;

export const Dropdown = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const DropdownLabel = styled.p`
  color: #6a707e;
  font-size: 15px;
  letter-spacing: 0.02em;
  line-height: 22px;
  margin: 0;
  margin-top: 15px;
  padding: 0;
`;

export const Dropzone = styled.div`
  background-color: #e5e6e8;
  max-height: 75vh;
  overflow-y: scroll;
  padding: 20px;
  width: 100%;

  #dz-id {
    outline: none;
  }
`;

export const DropzoneContent = styled.div`
  height: 100%;
`;

export const DropzoneHeader = styled.div`
  align-items: center;
  border: ${(props) => (props.dragActive ? "1px dashed black" : "none")};
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  font-size: 15px;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 22px;
  padding: 15px 0;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const DropzoneHeaderTitle = styled.div``;

export const DropzoneHeaderSubtitle = styled.div``;

export const DropzoneFiles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const DropzoneFile = styled.div`
  align-items: center;
  background: rgba(196, 196, 196, 0.2);
  display: flex;
  height: 64px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 25px;
  width: 100%;
`;

export const FileTitle = styled.p`
  flex-grow: 1;
  margin: 0;
  padding: 0;
`;

export const FileDelete = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;

  &:hover {
    cursor: pointer;
  }

  img {
    object-fit: contain;
  }
`;

export const Dropdowns = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownBox = styled.div`
  border: ${(props) => (props.invalid ? "1px solid #f00" : "1px solid #aaa")};
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px;
  width: 300px;
  max-width: 100%;
`;

export const HeaderLink = styled.div`
  margin-left: -16px;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonAtBottomMobile = styled.div`
  display: ${(props) => (props.isMobile ? "block" : "none")};
  button {
    padding: 10px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: ${(props) => props.theme.colors[0]};
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;
    margin-right: 5px;
    margin-top: 5px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }
  }
`;
export const TextInRed = styled.p`
  color: red;
`;
