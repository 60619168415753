import { Types } from "../actions/document.actions";

const INITIAL_STATE = {
  results: [],
  error: null,
  loading: false,
  loaded: false,
  pdf: null,
  pdfError: null,
  pdfLoading: false,
  pdfLoaded: false,
  pdfFields: [],
  pdfFieldsError: null,
  pdfFieldsLoading: false,
  pdfFieldsLoaded: false,
  pdfFieldsUpdated: [],
  pdfFieldsUpdatedError: null,
  pdfFieldsUpdatedLoading: false,
  pdfFieldsUpdatedLoaded: false,
};

const documents = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_DOCUMENT_TYPES:
      return {
        ...state,
        loading: true,
        loaded: false,
        results: [],
      };

    case Types.GET_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        results: action.payload,
        error: null,
      };

    case Types.GET_DOCUMENT_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        results: [],
        error: action.payload,
      };

    case Types.GET_DOCUMENT_PDF:
      return {
        ...state,
        pdfLoading: true,
        pdfLoaded: false,
        pdf: null,
        pdfError: null,
      };

    case Types.GET_DOCUMENT_PDF_SUCCESS:
      return {
        ...state,
        pdfLoading: false,
        pdfLoaded: true,
        pdf: action.payload,
        pdfError: null,
      };

    case Types.GET_DOCUMENT_PDF_ERROR:
      return {
        ...state,
        pdfLoading: false,
        pdfLoaded: false,
        pdf: null,
        pdfError: action.payload,
      };

    case Types.GET_DOCUMENT_PDF_FIELDS:
      return {
        ...state,
        pdfFieldsLoading: true,
        pdfFeildsLoaded: false,
        pdfFields: null,
        pdfFieldsError: null,
      };

    case Types.GET_DOCUMENT_PDF_FIELDS_SUCCESS:
      return {
        ...state,
        pdfFieldsLoading: false,
        pdfFeildsLoaded: true,
        pdfFields: action.payload,
        pdfFieldsError: null,
      };

    case Types.GET_DOCUMENT_PDF_FIELDS_ERROR:
      return {
        ...state,
        pdfFieldsLoading: false,
        pdfFeildsLoaded: false,
        pdfFields: null,
        pdfFieldsError: action.payload,
      };

    case Types.GET_DOCUMENT_PDF_FIELDS_UPDATED:
      return {
        ...state,
        pdfFieldsUpdatedLoading: true,
        pdfFieldsUpdatedLoaded: false,
        pdfFieldsUpdated: null,
        pdfFieldsUpdatedError: null,
      };

    case Types.GET_DOCUMENT_PDF_FIELDS_UPDATED_SUCCESS:
      return {
        ...state,
        pdfFieldsUpdatedLoading: false,
        pdfFieldsUpdatedLoaded: true,
        pdfFieldsUpdated: action.payload,
        pdfFieldsUpdatedError: null,
      };

    case Types.GET_DOCUMENT_PDF_FIELDS_UPDATED_ERROR:
      return {
        ...state,
        pdfFieldsUpdatedLoading: false,
        pdfFieldsUpdatedLoaded: false,
        pdfFieldsUpdated: null,
        pdfFieldsUpdatedError: action.payload,
      };

    default:
      return state;
  }
};

export default documents;
