import React from "react";
import * as Styled from "./ResendActivation.styled";
import l10n from "../../../../localization";
import { resendUserActivationMail } from "../../../../redux/actions/user/resendUserActivationMail.actions";
import { connect } from "react-redux";

function ResendActivation(props) {
  const resendActivation = () => {
    props.resendUserActivationMail({ email: props.email });
  };
  return (
    <Styled.LinkHref onClick={resendActivation}>
      {l10n.login.resend}
    </Styled.LinkHref>
  );
}

export default connect(
  null,
  {
    resendUserActivationMail
  }
)(ResendActivation);
