import styled from "styled-components";
import { Link as RLink } from "react-router-dom";

export const Header = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 256px;
  display: flex;
  background-color: ${props => props.theme.colors[0]};
  height: 60px;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 37px;
  align-items: center;
  z-index: 3;

  @media (max-width: 1024px) {
    left: 0;
    justify-content: space-between;
  }

  @media print {
    display: none;
  }
`;

export const RightNavigation = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderPart = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const MobileHeaderLogo = styled(RLink)`
  display: flex;
  flex-direction: row;
  left: -28px;
  position: relative;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const HeaderSeparator = styled.div`
  display: block;
  margin: auto;
`;
