import React from "react";
import styled from "styled-components";

export default function Text({ type, children, rest }) {
  if (!type) type = "p";

  const StyledText = styled(`${type}`)`
    font-size: ${(props) => props.theme.text[type].fontSize};
    margin: ${(props) => props.theme.text[type].margin};
  `;

  return <StyledText {...rest}>{children}</StyledText>;
}
