import React from "react";
import { CardBody } from "reactstrap";
import { Line } from "react-chartjs-2";
import * as Styled from "./LineGraph.styles";
import moment from "moment/moment";
import l10n from "../../localization";
import theme from "../../theme/default";
import Helpers from "../../helpers/helpers";

const LineGraph = ({ readings, type }) => {
  const filterReadingsByType = (type) => {
    return readings.map((reading) =>
      reading.typeOfReading === type ? reading.consumption : null
    );
  };

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, "rgba(13, 157, 219, 0.5)");
    gradient.addColorStop(1, "rgba(13, 157, 219, 0)");

    const dates = readings.map((reading) => {
      if (type === "readings") return moment(reading.date).format("DD.MM.YY");

      return moment(reading.invoiceIssueDate).format("DD.MM.YY");
    });

    const settings = {
      pointBorderWidth: 2,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 4,
      pointRadius: 4,
      pointHitRadius: 10,
    };

    return {
      labels: dates,
      datasets:
        type === "readings"
          ? [
              {
                label: l10n.reports.readingType1,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("O"),
                pointBackgroundColor: theme.colors[42],
                pointHoverBackgroundColor: theme.colors[42],
                ...settings,
              },
              {
                label: l10n.reports.readingType2,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("P"),
                pointBackgroundColor: theme.colors[43],
                pointHoverBackgroundColor: theme.colors[43],
                ...settings,
              },
              {
                label: l10n.reports.readingType4,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("S"),
                pointBackgroundColor: theme.colors[44],
                pointHoverBackgroundColor: theme.colors[44],
                ...settings,
              },
              {
                label: l10n.reports.readingType3,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("M"),
                pointBackgroundColor: theme.colors[45],
                pointHoverBackgroundColor: theme.colors[45],
                ...settings,
              },
              {
                label: l10n.reports.readingType5,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("B"),
                pointBackgroundColor: theme.colors[46],
                pointHoverBackgroundColor: theme.colors[46],
                ...settings,
              },
              {
                label: l10n.reports.readingType6,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("F"),
                pointBackgroundColor: theme.colors[47],
                pointHoverBackgroundColor: theme.colors[47],
                ...settings,
              },
              {
                label: l10n.reports.readingType7,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("C"),
                pointBackgroundColor: theme.colors[48],
                pointHoverBackgroundColor: theme.colors[48],
                ...settings,
              },
              {
                label: l10n.reports.readingType8,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("W"),
                pointBackgroundColor: theme.colors[49],
                pointHoverBackgroundColor: theme.colors[49],
                ...settings,
              },
              {
                label: l10n.reports.readingType9,
                backgroundColor: "transparent",
                borderColor: "transparent",
                data: filterReadingsByType("K"),
                pointBackgroundColor: theme.colors[50],
                pointHoverBackgroundColor: theme.colors[50],
                ...settings,
              },
              {
                label: "Consumptions",
                borderColor: "#109CF1",
                borderWidth: "2",
                backgroundColor: gradient,
                data: readings.map((reading) => {
                  return reading.consumption;
                }),
              },
            ]
          : [
              {
                label: "Invoices",
                borderColor: "#109CF1",
                borderWidth: "2",
                backgroundColor: "transparent",
                data: readings.map((reading) => {
                  return reading.invoiceValueAdjusted;
                }),
                pointBorderColor: "#109CF1",
                pointBackgroundColor: "#109CF1",
                pointBorderWidth: 2,
                pointHitRadius: 15,
                pointHoverBackgroundColor: "#109CF1",
                pointHoverBorderWidth: 4,
                lineTension: 0,
              },
            ],
    };
  };

  return (
    <Styled.Card>
      <CardBody style={{ padding: "5px" }}>
        <Line
          options={{
            tooltips: {
              displayColors: false,
              backgroundColor: "rgba(25, 42, 62, 0.75)",
              enabled: true,
              mode: "label",
              bodyFontSize: 13,
              bodyFontColor: "white",
              yPadding: 6,
              xPadding: 8,
              cornerRadius: 12,
              callbacks: {
                title: (tooltipItems) => {
                  return tooltipItems[0].xLabel;
                },
                afterLabel: (tooltipItems) => {
                  if (tooltipItems.datasetIndex === 9) return;

                  if (type === "readings") {
                    if (
                      tooltipItems.datasetIndex + 1 > 0 &&
                      tooltipItems.datasetIndex + 1 < 10
                    ) {
                      return l10n.reports[
                        `readingType${tooltipItems.datasetIndex + 1}`
                      ];
                    }

                    if (tooltipItems.datasetIndex === 0) {
                      if (readings.length > tooltipItems.index) {
                        return `${l10n.reports.meterCode}: ${
                          readings[tooltipItems.index].meterCode
                        }`;
                      }
                    }
                  }

                  return ``;
                },
                label: (tooltipItems, data) => {
                  if (
                    type === "readings" &&
                    tooltipItems &&
                    tooltipItems.datasetIndex === 9
                  ) {
                    let value = tooltipItems.yLabel;

                    if (!isNaN(value)) {
                      value = Helpers.formatNumber(value);
                    }

                    return `${l10n.reports.accountedConsumption}: ${value} m3`;
                  }

                  if (type === "invoices")
                    return `${l10n.reports.accountedConsumption}: ${tooltipItems.yLabel} EUR`;

                  return;
                },
              },
            },
            legend: {
              display: false,
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                    borderDash: [10, 10],
                    color: "#c4c4c4",
                    zeroLineBorderDash: [10, 10],
                    zeroLineColor: "#c4c4c4",
                  },
                  ticks: {
                    fontColor: "#4C5862",
                    fontSize: 12,
                    min: 0,
                    padding: 8,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                    min: 0,
                  },
                  ticks: {
                    fontColor: "#4C5862",
                    fontSize: 12,
                    userCallback: function (item, index) {
                      if (type === "invoices") {
                        if (
                          (index === 0 || index === readings.length - 1) &&
                          type === "invoices"
                        )
                          return item;

                        return "";
                      }

                      return item;
                    },
                    autoSkip: type === "readings",
                    maxRotation: 0,
                    minRotation: 0,
                    min: 2,
                    autoSkipPadding: 30,
                    padding: 10,
                  },
                },
              ],
            },
            maintainAspectRatio: false,
            animation: false,
          }}
          data={data}
        />
      </CardBody>
    </Styled.Card>
  );
};

export default LineGraph;
