import React, { useState } from "react";
import * as Styled from "./InProcess.styles";
import i10n from "../../../../localization";

const InProcessInfo = ({ isPayed }) => {
  const [showMessage, setShowMessage] = useState(false);
  if (isPayed !== "In process") return null;

  return (
    <Styled.InProcess>
      <i
        className="fas fa-info-circle"
        onMouseEnter={() => setShowMessage(true)}
        onMouseLeave={() => setShowMessage(false)}
        onClick={(e) => {
          e.stopPropagation();
          setShowMessage(!showMessage);
        }}
      />
      <Styled.Message show={showMessage}>
        <p>{i10n.invoices.paymentProcessingMessage1}</p>
      </Styled.Message>
    </Styled.InProcess>
  );
};

export default InProcessInfo;
