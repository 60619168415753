import React, { useState, useEffect } from "react";
import { FormGroup, Label, Form, Input } from "reactstrap";
import * as Styled from "./PersonalDataForm.styles";
import { connect } from "react-redux";
import { updateProfile } from "../../../../redux/actions/user/userProfile.actions";
import l10n from "../../../../localization";

function PersonalDataForm(props) {
  const INITIAL_USER_VALIDATION = {
    name: "",
    userType: "",
    address: "",
    post: "",
    postCode: "",
    email: "",
    vatId: "",
    userCode: "",
  };

  const [user, setUser] = useState({ ...INITIAL_USER_VALIDATION });

  useEffect(() => {
    setUser({
      name: props.user.userData.data.name,
      userType: props.user.userData.data.userType,
      address: props.user.userData.data.address,
      post: props.user.userData.data.post,
      postCode: props.user.userData.data.postCode,
      email: props.user.userData.data.email,
      vatId: props.user.userData.data.vatId,
      userCode: props.user.userData.data.userCode,
    });
  }, [props.user.userData]);

  return (
    <>
      <Styled.Card>
        <Styled.Header>
          <Styled.HeaderTitle>{l10n.profile.personalData}</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Body>
          <Form>
            <FormGroup row className="d-flex align-items-baseline">
              <Styled.PersonalDataKeyCol>
                <Label for="name">{l10n.profile.name}</Label>
              </Styled.PersonalDataKeyCol>
              <Styled.PersonalDataValueCol>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={user.name}
                  disabled
                />
              </Styled.PersonalDataValueCol>
            </FormGroup>

            <FormGroup row className="d-flex align-items-baseline">
              <Styled.PersonalDataKeyCol>
                <Label for="address">{l10n.profile.address}</Label>
              </Styled.PersonalDataKeyCol>
              <Styled.PersonalDataValueCol>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={user.address}
                  disabled
                />
              </Styled.PersonalDataValueCol>
            </FormGroup>

            <FormGroup row className="d-flex align-items-baseline">
              <Styled.PersonalDataKeyCol>
                <Label for="post">{l10n.profile.post}</Label>
              </Styled.PersonalDataKeyCol>
              <Styled.PersonalDataValueCol>
                <Input
                  type="text"
                  name="post"
                  id="post"
                  value={`${user.post || ""} ${user.postCode || ""}`}
                  disabled
                />
              </Styled.PersonalDataValueCol>
            </FormGroup>

            <FormGroup row className="d-flex align-items-baseline">
              <Styled.PersonalDataKeyCol>
                <Label for="vatId">{l10n.profile.vatId}</Label>
              </Styled.PersonalDataKeyCol>
              <Styled.PersonalDataValueCol>
                <Input
                  type="text"
                  name="vatId"
                  id="vatId"
                  value={user.vatId}
                  disabled
                />
              </Styled.PersonalDataValueCol>
            </FormGroup>
          </Form>
        </Styled.Body>
      </Styled.Card>
      <Styled.Card>
        <Styled.Header>{l10n.profile.contactData}</Styled.Header>
        <Styled.Body>
          <Form>
            <FormGroup row className="d-flex align-items-baseline">
              <Styled.ContactDataKeyCol>
                <Label for="post">{l10n.profile.telephone}</Label>
              </Styled.ContactDataKeyCol>
              <Styled.ContactDataValueCol>
                <Input
                  type="text"
                  name="post"
                  id="post"
                  value={`${user.post || ""} ${user.postCode || ""}`}
                  disabled
                />
              </Styled.ContactDataValueCol>
            </FormGroup>

            <FormGroup row className="d-flex align-items-baseline">
              <Styled.ContactDataKeyCol>
                <Label for="email">{l10n.profile.email}</Label>
              </Styled.ContactDataKeyCol>
              <Styled.ContactDataValueCol>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={user.email}
                  disabled
                />
              </Styled.ContactDataValueCol>
            </FormGroup>
          </Form>
        </Styled.Body>
      </Styled.Card>
    </>
  );
}

export default connect(({ user }) => ({ user }), { updateProfile })(
  PersonalDataForm
);
