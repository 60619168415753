import styled from "styled-components";
import { Link as RLink } from "react-router-dom";

export const Sidebar = styled.aside`
  position: fixed;
  z-index: 5;
  width: 256px;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${props => props.theme.colors[0]};
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 18px 0 ${props => props.theme.colors[16]};

  @media (max-width: 1024px) {
    z-index: 999;
    width: 100%;
    top: 75px;
    transition: transform 0.2s ease-in;
    transform: ${props =>
      props.open ? "translateX(0%)" : "translateX(-100%)"};
  }

  @media print {
    display: none;
  }
`;

export const SidebarLogo = styled(RLink)`
  @media (max-width: 1024px) {
    display: none;
  }
`;
