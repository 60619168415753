import React, { useEffect, useState } from "react";
import { ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import * as Styled from "./requestModal.styles";
import l10n from "../../../localization";
import { connect } from "react-redux";
import { saveUserDocuments } from "../../../redux/actions/requests.actions";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom";

const RequestModal = (props) => {
  const history = useHistory();
  const { open, onClose } = props;
  const [sendDisabled, setSendDisabled] = useState(false);

  useEffect(() => {
    if (props.requests.documentSaved) {
      setTimeout(() => {
        history.push("/requests");
      }, 5000);
    }
    // eslint-disable-next-line
  }, [props.requests.documentSaved]);

  console.log(props);
  return (
    <Styled.ModalHolder isOpen={open}>
      <ModalHeader>
        <Styled.HeaderTitle>{l10n.requests.modalTitle}</Styled.HeaderTitle>
      </ModalHeader>
      <ModalBody>
        <Styled.Content>
          {!props.requests.documentSaving ? (
            props && props.requests && props.requests.documentSaved ? (
              "Vaši su dokumenti uspješno poslani. Bit ćete preusmjereni na stranicu zahtjeva nakon 5 sekundi."
            ) : (
              <div>Ako ste sigurni pritisnite na gumb "Pošalji"</div>
            )
          ) : (
            <Loader inline />
          )}
        </Styled.Content>
      </ModalBody>
      <ModalFooter>
        <Styled.Footer>
          <Button color="secondary" onClick={onClose}>
            {l10n.modal.close}
          </Button>

          <Styled.FooterSend>
            <Button
              disabled={sendDisabled}
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSendDisabled(true);
                props.onUpload();
              }}
            >
              {l10n.requests.send}
            </Button>
          </Styled.FooterSend>
        </Styled.Footer>
      </ModalFooter>
    </Styled.ModalHolder>
  );
};

export default connect(({ requests }) => ({ requests }), {
  saveUserDocuments,
})(RequestModal);
