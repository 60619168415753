import styled from "styled-components";
import { Button as RButton } from "reactstrap";

export const Button = styled(RButton)`
  text-transform: uppercase;
`;

export const ContentWrap = styled.div`
  padding: 90px 80px 40px;
  height: 100%;

  @media (min-width: 1200px) {
    min-height: 600px;
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    min-height: 500px;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    min-height: 400px;
  }

  @media (max-width: 575px) {
    padding: 20px;
  }
`;
