import React, { useState } from "react";
import * as Styled from "./HeaderProfile.styles";
import ProfileDropdown from "../ProfileDropdown";
import { connect } from "react-redux";
import { IconchevronDown } from "../../../../../../components/Icons/Icons";

const HeaderProfile = (props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  if (!props.user.userData.success) return null;

  return (
    <Styled.Profile
      onMouseEnter={() => setDropdownVisible(true)}
      onMouseLeave={() => setDropdownVisible(false)}
    >
      <IconchevronDown />
      <span>{props.user.userData.data.name}</span>
      <ProfileDropdown visible={dropdownVisible} />
    </Styled.Profile>
  );
};

export default connect(({ user }) => ({ user }), null)(HeaderProfile);
