import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { logoutUser } from "../actions/user/user.actions";
import * as actions from "../actions/meter.actions";
import * as api from "../api/meter.api";

function* getMeters() {
  try {
    const state = yield select();

    const meters = yield call(
      api.getUserMeters,
      state.user.userData.data.userId
    );
    yield put(actions.getMetersSuccess(meters.data));
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    yield put(
      actions.meterError({
        error: "An error occurred when trying to get meters",
      })
    );
  }
}

function* watchGetMetersRequest() {
  yield takeLatest(actions.Types.GET_METERS, getMeters);
}

function* newMeterReading({ payload }) {
  try {
    const state = yield select();

    yield call(api.newMeterReading, {
      userId: state.user.userData.data.userId,
      meterSiteCode: payload.meterSiteCode,
      meterCode: payload.meterCode,
      readingDate: payload.readingDate,
      value: parseFloat(payload.value),
    });

    yield put(actions.newMeterReadingSuccess());
    yield put(actions.getMeters());
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    if (err.response.data.Messages) {
      yield put(actions.meterError(err.response.data.Messages));
    } else {
      yield put(actions.meterError(err.response.data.messages));
    }
  }
}

function* watchNewMeterReading() {
  yield takeLatest(actions.Types.NEW_METER_READING, newMeterReading);
}

function* newMeterReadingChildren({ payload }) {
  try {
    const state = yield select();

    yield call(api.newMeterReadingChildren, {
      userId: state.user.userData.data.userId,
      meterSiteCode: payload.meterSiteCode,
      meterCode: payload.meterCode,
      readingDate: payload.readingDate,
      value: parseFloat(payload.value),
      meterChildrenInputs: payload.meterChildrenInputs,
    });

    yield put(actions.newMeterReadingChildrenSuccess());
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    if (err.response.data.Messages) {
      yield put(actions.meterError(err.response.data.Messages));
    } else {
      yield put(actions.meterError(err.response.data.messages));
    }
  }
}

function* watchNewMeterReadingChildren() {
  yield takeLatest(
    actions.Types.NEW_METER_READING_CHILDREN,
    newMeterReadingChildren
  );
}

function* enableManualReading({ payload }) {
  try {
    const state = yield select();

    yield call(api.enableMeterManualReading, {
      userId: state.user.userData.data.userId,
      meterId: payload.meterId,
    });
    yield put(actions.getMeters());
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    yield put(actions.meterError(err.response.data.Messages));
  }
}

function* watchEnableManualReading() {
  yield takeLatest(
    actions.Types.ENABLE_METER_MANUAL_READING,
    enableManualReading
  );
}

function* meterReadingsByDay({ payload }) {
  try {
    const state = yield select();

    const metersReadings = yield call(api.meterReadingsByDay, {
      userId: state.user.userData.data.userId,
      meterSiteCode: payload.meterSiteCode,
      meterCode: payload.meterCode,
      dateFrom: payload.dateFrom,
      dateTo: payload.dateTo,
    });
    yield put(actions.meterReadingsByDaySuccess(metersReadings.data));
  } catch (err) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logoutUser());
      }

      if (err.response.data) {
        yield put(actions.meterError(err.response.data.Messages));
      }
    }
  }
}

function* watchMeterReadingsByDay() {
  yield takeLatest(actions.Types.METER_READINGS_BY_DAY, meterReadingsByDay);
}

export default [
  fork(watchGetMetersRequest),
  fork(watchNewMeterReading),
  fork(watchEnableManualReading),
  fork(watchMeterReadingsByDay),
  fork(watchNewMeterReadingChildren),
];
