import { call, fork, put, takeLatest, select } from "redux-saga/effects";
import {
  Types,
  updateProfileSuccess,
  updateProfileError
} from "../../actions/user/userProfile.actions";
import * as api from "../../api/user.api";
import { getUser } from "./user.sagas";

function* updateProfile({ payload }) {
  try {
    const state = yield select();
    yield call(api.updateProfile, {
      userId: state.user.userData.data.userId,
      ...payload
    });
    yield put(updateProfileSuccess());
    yield call(getUser);
  } catch (err) {
    yield put(updateProfileError(err.response.data.Messages));
  }
}

function* watchUpdateProfile() {
  yield takeLatest(Types.UPDATE_PROFILE, updateProfile);
}

export default [fork(watchUpdateProfile)];
