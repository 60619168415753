import styled from "styled-components";

export const Card = styled.div`
  background: ${(props) => props.theme.colors[0]};
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
  border-radius: 4px;
  margin: 0 0 25px 0;
  overflow-y: auto;
`;

export const CardHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  display: flex;
  flex-direction: column;
  height: 100px;
  padding: 10px 20px;

  @media (max-width: 767px) {
    height: 70px;
  }

  @media (min-width: 1440px) {
    height: 70px;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const HeaderBottom = styled.div`
  width: 100%;
`;

export const HeaderData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const HeaderLegend = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const HeaderText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  color: ${(props) => props.theme.colors[28]};
`;

export const HeaderLegendText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.01em;
  margin: 0;
  opacity: 0.5;
  color: ${(props) => props.theme.colors[18]};
`;

export const CardChart = styled.div`
  padding: 15px;
`;

export const MeterData = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 10px 20px;
`;

export const MeterDataItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 33%;
  padding: 5px 0;
`;

export const MeterDataKey = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[18]};
  opacity: 0.5;
  min-width: 120px;
  display: inline-block;
  margin: 0;
  padding: 0;
`;

export const MeterDataValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[23]};
  margin: 0;
  padding: 0;
`;
