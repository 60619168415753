export const Types = {
  GET_DOCUMENT_TYPES: "documents/get_document_types",
  GET_DOCUMENT_TYPES_SUCCESS: "documents/get_document_types_success",
  GET_DOCUMENT_TYPES_ERROR: "documents/get_document_types_error",
  GET_DOCUMENT_PDF: "documents/get_document_pdf",
  GET_DOCUMENT_PDF_SUCCESS: "documents/get_document_pdf_success",
  GET_DOCUMENT_PDF_ERROR: "documents/get_document_pdf_error",
  GET_DOCUMENT_PDF_FIELDS: "documents/get_document_fields_pdf",
  GET_DOCUMENT_PDF_FIELDS_SUCCESS: "documents/get_document_pdf_fields_success",
  GET_DOCUMENT_PDF_FIELDS_ERROR: "documents/get_document_pdf_fields_error",
  GET_DOCUMENT_PDF_FIELDS_UPDATED: "documents/get_document_fields_pdf_updated",
  GET_DOCUMENT_PDF_FIELDS_UPDATED_SUCCESS:
    "documents/get_document_pdf_fields_updated_success",
  GET_DOCUMENT_PDF_FIELDS_UPDATED_ERROR:
    "documents/get_document_pdf_fields_updated_error",
};

export const getDocumentTypes = () => ({
  type: Types.GET_DOCUMENT_TYPES,
});

export const getDocumentTypesSuccess = (data) => ({
  type: Types.GET_DOCUMENT_TYPES_SUCCESS,
  payload: data,
});

export const getDocumentTypesError = (error) => ({
  type: Types.GET_DOCUMENT_TYPES_ERROR,
  payload: error,
});

export const getDocumentPDFFields = (
  documentName,
  documentId,
  meterSiteCode,
  meterCode
) => ({
  type: Types.GET_DOCUMENT_PDF_FIELDS,
  payload: { documentName, documentId, meterSiteCode, meterCode },
});

export const getDocumentPDFFieldsSuccess = (data) => ({
  type: Types.GET_DOCUMENT_PDF_FIELDS_SUCCESS,
  payload: data,
});

export const getDocumentPDFFieldsError = (error) => ({
  type: Types.GET_DOCUMENT_PDF_FIELDS,
  payload: error,
});

export const getDocumentPDF = (documentName, documentId, fields) => ({
  type: Types.GET_DOCUMENT_PDF,
  payload: { documentName, documentId, fields },
});

export const getDocumentPDFSuccess = (data) => ({
  type: Types.GET_DOCUMENT_PDF_SUCCESS,
  payload: data,
});

export const getDocumentPDFError = (error) => ({
  type: Types.GET_DOCUMENT_PDF,
  payload: error,
});

export const getDocumentPDFFieldsUpdated = (
  documentName,
  documentId,
  meterSiteCode,
  meterCode
) => ({
  type: Types.GET_DOCUMENT_PDF_FIELDS_UPDATED,
  payload: { documentName, documentId, meterSiteCode, meterCode },
});

export const getDocumentPDFFieldsUpdatedSuccess = (data) => ({
  type: Types.GET_DOCUMENT_PDF_FIELDS_UPDATED_SUCCESS,
  payload: data,
});

export const getDocumentPDFFieldsUpdatedError = (error) => ({
  type: Types.GET_DOCUMENT_PDF_FIELDS_UPDATED,
  payload: error,
});
