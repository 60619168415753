import React, { useEffect, useState } from "react";
import * as Styled from "./requestsForm.styles";
import { connect } from "react-redux";
import {
  getDocumentPDFFields,
  getDocumentTypes,
  getDocumentPDF,
  getDocumentPDFFieldsUpdated,
} from "../../redux/actions/document.actions";
import { Button, Col, Input, NavLink, Row } from "reactstrap";
import Loader from "../../components/Loader";
import l10n from "../../localization";
import { useHistory, useParams } from "react-router-dom";
import RequestModal from "./RequestModal/RequestModal";
import Label from "reactstrap/lib/Label";
import Radio from "../../components/RadioButton/Radio";
import Dropzone from "react-dropzone";
import removeFileIcon from "../../assets/remove_file_icon.svg";
import OptionBox from "../../components/OptionBox";
import { getMeters } from "../../redux/actions/meter.actions";
import { saveUserDocuments } from "../../redux/actions/requests.actions";
import { isBrowser } from "react-device-detect";

const RequestsForm = (props) => {
  const notReqFields = ["Broj vodomjera"];
  const { documentId } = useParams();
  const [modalOpened, setModalOpened] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldsValidation, setFieldsValidation] = useState([]);
  const [fieldsValidationBoilerPlate, setFieldsValidationBoilerPlate] =
    useState([]);
  const [files, setFiles] = useState([]);
  const [meterCode, setMeterCode] = useState("select");
  const [meterSiteCode, setMeterSiteCode] = useState("select");
  const history = useHistory();

  useEffect(() => {
    props.getDocumentPDFFields(realValueOfName, documentId, "", "");
    props.getDocumentTypes();
    props.getMeters();

    return () => {
      setFiles([]);
      setMeterCode("select");
      setMeterSiteCode("select");
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(props.documents.pdfFields);

    if (
      props.documents.pdfFields !== [] &&
      props.documents.pdfFields !== null
    ) {
      setFields(props.documents.pdfFields);

      let validationFields = JSON.parse(
        JSON.stringify(props.documents.pdfFields)
      );
      Object.keys(validationFields).forEach((key, idx) => {
        validationFields[key] = false;
      });

      setFieldsValidationBoilerPlate(validationFields);
      setFieldsValidation(validationFields);
    }
  }, [props.documents.pdfFields]);

  let tempValue = props.documents.results.filter(
    (x) => x.documentId === Number(documentId)
  );
  let realValueOfName = "";
  if (tempValue.length > 0) {
    realValueOfName = tempValue[0].name;
  }

  // Functions

  const onUpload = () => {
    let result = validation();
    if (result !== null) {
      setFieldsValidation(result);
      return;
    }

    setFieldsValidation(fieldsValidationBoilerPlate);

    const copyState = fields;
    copyState["Broj mjernog mjesta"] = meterSiteCode.trim().replaceAll("-", "");
    copyState["Broj vodomjera"] =
      meterCode === "Odaberite vodomjer" || meterCode === "select"
        ? ""
        : meterCode.trim();

    props.getDocumentPDF(realValueOfName, documentId, copyState);
  };

  const renderUploadModal = () => {
    return (
      <RequestModal
        open={modalOpened}
        onClose={() => {
          setModalOpened(false);
          //props.getUserDocuments();
        }}
        onUpload={onSave}
      />
    );
  };

  const onFilesDropped = (acceptedFiles) => {
    let allFiles = [...files, ...acceptedFiles];

    if (allFiles.length > 10) {
      allFiles = allFiles.slice(0, 10);
    }

    setFiles(allFiles);
  };

  const validation = () => {
    //deep copy the OBJ.
    let errors = false;
    let startingValidationFields = JSON.parse(
      JSON.stringify(fieldsValidationBoilerPlate)
    );

    //Check for global errorrs
    const copyState = fields;

    Object.keys(copyState).forEach((key, idx) => {
      if (notReqFields.includes(key)) return;

      var error = false;

      if (key === "Email") {
        //Check email
        //eslint-disable-next-line
        let regexEmailValidate =
          //eslint-disable-next-line
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        error = !regexEmailValidate.test(copyState[key]);
      } else {
        if (copyState[key] !== null) {
          error = copyState[key].trim() === "";
        } else {
          error = true;
        }
      }

      if (error && key !== "Broj mjernog mjesta" && key !== "Broj vodomjera")
        errors = true;

      startingValidationFields[key] = error;
    });

    //Check for specifix errors
    var mscError =
      meterSiteCode === "Odaberite mjesto potrošnje" ||
      meterSiteCode === "select";
    //var mcError = meterCode === "Odaberite vodomjer" || meterCode === "select";

    errors = errors || mscError;

    startingValidationFields["Broj mjernog mjesta"] = mscError;
    //startingValidationFields["Broj vodomjera"] = mcError;

    return errors ? startingValidationFields : null;
  };

  const onSave = async () => {
    let result = validation();
    if (result !== null) {
      setFieldsValidation(result);
      return;
    }

    setFieldsValidation(fieldsValidationBoilerPlate);

    const copyState = fields;
    copyState["Broj mjernog mjesta"] = meterSiteCode.trim().replaceAll("-", "");
    copyState["Broj vodomjera"] =
      meterCode === "Odaberite vodomjer" || meterCode === "select"
        ? ""
        : meterCode.trim();

    let data = [];

    for (const file of files) {
      let base64 = await toBase64(file);
      base64 = base64.split(",")[1];
      data.push({ fileName: file.name, fileData: base64 });
    }

    const payload = {
      typeOfRequest: realValueOfName,
      documentId: documentId,
      pdfFields: copyState,
      data,
    };

    props.saveUserDocuments(payload);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  // Renders

  const renderFile = (file, index) => {
    return (
      <Styled.DropzoneFile
        key={`${file.name}_${file.size}_${index}`}
        onClick={(e) => e.stopPropagation()}
      >
        <Styled.FileTitle>{file.name}</Styled.FileTitle>
        <Styled.FileDelete
          onClick={(e) => {
            e.stopPropagation();
            setFiles(files.filter((item) => item.name !== file.name));
          }}
        >
          <img src={removeFileIcon} alt="Remove file" />
        </Styled.FileDelete>
      </Styled.DropzoneFile>
    );
  };

  const handleRadioButton = (value, key) => {
    var copyFields = { ...fields };
    copyFields[key] = value % 2 === 0 ? "Off" : "Yes";

    setFields(copyFields);
  };

  const handleInputChanged = (value, key) => {
    console.log(value, key);
    var copyFields = { ...fields };
    copyFields[key] = value.target.value;

    setFields(copyFields);
  };

  const generateMeters = () => {
    const filteredMeters = props.meters.result.filter(
      (meter) => meter.meterSiteCode === meterSiteCode
    );
    if (filteredMeters.length > 0) {
      const targetMeter = filteredMeters[0];
      return targetMeter.userMeters.map((userMeter) => {
        return {
          slug: userMeter.meterCode,
          value: userMeter.meterCode,
        };
      });
    }

    return [];
  };

  const handleModalOpen = () => {
    let result = validation();

    console.log("RESULLTT", result);
    if (result !== null) {
      setFieldsValidation(result);
      return;
    }

    setFieldsValidation(fieldsValidationBoilerPlate);
    setModalOpened(true);
  };

  const generatePdfFields = () => {
    const columnLength = Object.keys(fields).length;
    return (
      <Row>
        <Styled.RowedFields>
          {Object.keys(fields)
            .slice(0, columnLength / 2 + 1)
            .map((key, idx) => {
              return (
                <Styled.WrappedInputAndLabel key={key}>
                  <Label>
                    {key} {!notReqFields.includes(key) && "*"}
                  </Label>
                  {key.includes("?") ? (
                    <Styled.RadioRows>
                      <Label className="static">{"Da"}</Label>
                      <Radio
                        value={idx * 2 + 1}
                        selected={
                          fields[key] === "Yes" ? idx * 2 + 1 : idx * 2 + 2
                        }
                        onChange={(val) => handleRadioButton(val, key)}
                      />
                      <Label className="static">{"Ne"}</Label>
                      <Radio
                        value={idx * 2 + 2}
                        selected={
                          fields[key] === "Yes" ? idx * 2 + 1 : idx * 2 + 2
                        }
                        onChange={(val) => handleRadioButton(val, key)}
                      />
                    </Styled.RadioRows>
                  ) : key.includes("Broj mjernog mjesta") ? (
                    <Styled.DropdownBox invalid={fieldsValidation[key]}>
                      <OptionBox
                        placeholder={l10n.requests.selectLocation}
                        wrapperWidth="100%"
                        options={[
                          {
                            slug: l10n.requests.selectLocation,
                            value: l10n.requests.selectLocation,
                          },
                          ...props.meters.result.map((meter) => {
                            return {
                              slug: meter.meterSiteCode,
                              value: `${meter.siteAddress}`,
                            };
                          }),
                        ]}
                        selected={meterSiteCode}
                        onSelect={(option) => {
                          setMeterSiteCode(option.slug);
                          setMeterCode("select");
                          if (
                            (documentId === "1" || documentId === "2") &&
                            option.value.includes("(")
                          ) {
                            let copyOfState = fields;
                            copyOfState["Adresa priključka"] =
                              option.value.split("(")[0];

                            setFields(copyOfState);
                          }
                        }}
                        showSlug={true}
                      />
                    </Styled.DropdownBox>
                  ) : key.includes("Broj vodomjera") ? (
                    <div>
                      <Styled.DropdownBox invalid={fieldsValidation[key]}>
                        <OptionBox
                          placeholder={l10n.requests.selectMeter}
                          wrapperWidth="100%"
                          options={[
                            {
                              slug: l10n.requests.selectMeter,
                              value: l10n.requests.selectMeter,
                            },
                            ...generateMeters(),
                          ]}
                          selected={meterCode}
                          onSelect={(option) => setMeterCode(option.slug)}
                          showSlug={true}
                        />
                      </Styled.DropdownBox>
                    </div>
                  ) : (
                    <Input
                      disabled={key.includes("Datum zahtjeve")}
                      value={fields[key]}
                      onChange={(val) => handleInputChanged(val, key)}
                      invalid={fieldsValidation[key]}
                    />
                  )}
                </Styled.WrappedInputAndLabel>
              );
            })}
        </Styled.RowedFields>

        <Styled.RowedFields>
          {Object.keys(fields)
            .slice(columnLength / 2 + 1, columnLength)
            .map((key, idx) => {
              return (
                <Styled.WrappedInputAndLabel key={key}>
                  <Label>
                    {key}
                    {!notReqFields.includes(key) && "*"}
                  </Label>
                  {key.includes("?") ? (
                    <Styled.RadioRows>
                      <Label className="static">{"Da"}</Label>
                      <Radio
                        value={idx * 2 + 1}
                        selected={
                          fields[key] === "Yes" ? idx * 2 + 1 : idx * 2 + 2
                        }
                        onChange={(val) => handleRadioButton(val, key)}
                      />
                      <Label className="static">{"Ne"}</Label>
                      <Radio
                        value={idx * 2 + 2}
                        selected={
                          fields[key] === "Yes" ? idx * 2 + 1 : idx * 2 + 2
                        }
                        onChange={(val) => handleRadioButton(val, key)}
                      />
                    </Styled.RadioRows>
                  ) : key.includes("Broj mjernog mjesta") ? (
                    <Styled.DropdownBox invalid={fieldsValidation[key]}>
                      <OptionBox
                        placeholder={l10n.requests.selectLocation}
                        wrapperWidth="100%"
                        options={[
                          {
                            slug: l10n.requests.selectLocation,
                            value: l10n.requests.selectLocation,
                          },
                          ...props.meters.result.map((meter) => {
                            return {
                              slug: meter.meterSiteCode,
                              value: `${meter.siteAddress}`,
                            };
                          }),
                        ]}
                        selected={meterSiteCode}
                        onSelect={(option) => {
                          setMeterSiteCode(option.slug);
                          setMeterCode("select");
                          if (
                            (documentId === "1" || documentId === "2") &&
                            option.value.includes("(")
                          ) {
                            let copyOfState = fields;
                            copyOfState["Adresa priključka"] =
                              option.value.split("(")[0];

                            setFields(copyOfState);
                          }
                        }}
                        showSlug={true}
                      />
                    </Styled.DropdownBox>
                  ) : key.includes("Broj vodomjera") ? (
                    <Styled.DropdownBox invalid={fieldsValidation[key]}>
                      <OptionBox
                        placeholder={l10n.requests.selectMeter}
                        wrapperWidth="100%"
                        options={[
                          {
                            slug: l10n.requests.selectMeter,
                            value: l10n.requests.selectMeter,
                          },
                          ...generateMeters(),
                        ]}
                        selected={meterCode}
                        onSelect={(option) => {
                          setMeterCode(option.slug);
                          // props.getDocumentPDFFieldsUpdated(
                          //   "",
                          //   documentId,
                          //   meterSiteCode,
                          //   option.slug
                          // );
                        }}
                        showSlug={true}
                      />
                    </Styled.DropdownBox>
                  ) : (
                    <Input
                      disabled={key.includes("Datum zahtjeve")}
                      value={fields[key]}
                      onChange={(val) => handleInputChanged(val, key)}
                      invalid={!!fieldsValidation[key]}
                    />
                  )}
                </Styled.WrappedInputAndLabel>
              );
            })}
        </Styled.RowedFields>
      </Row>
    );
  };

  if (
    props.documents.pdfFieldsLoading ||
    props.documents.loading ||
    props.meters.loading
  )
    return <Loader />;

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Styled.HeaderLink>
            <NavLink onClick={() => history.push("/requests")}> Nazad </NavLink>
          </Styled.HeaderLink>

          <Styled.Header>
            <Styled.HeaderLeft>
              <Styled.Title>
                {l10n.requestForms.updateFields + ": " + realValueOfName}
              </Styled.Title>
            </Styled.HeaderLeft>
            <Styled.HeaderRight isMobile={!isBrowser}>
              <Button type="submit" color="primary" onClick={() => onUpload()}>
                {l10n.requests.pdfPreview}
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={() => handleModalOpen()}
              >
                {l10n.requests.button}
              </Button>
            </Styled.HeaderRight>
          </Styled.Header>
          <Styled.Content>
            {generatePdfFields()}
            <div>
              {documentId === "1" && (
                <div>
                  Dokumentacija koju je potrebno priložiti (dokaz o vlasništvu
                  nekretnine):
                </div>
              )}

              {(documentId === "2" || documentId === "6") && (
                <div>Dokumentacija koju je potrebno priložiti:</div>
              )}

              {documentId === "4" && <div>Zahtjevu treba priložiti:</div>}

              {documentId === "5" && <div>Zahtjevu treba priložiti:</div>}

              {documentId === "1" && (
                <div>
                  1. vlasnički list ili <br></br>
                  2. kupoprodajni ugovor,<br></br>
                  3. rješenje o nasljeđivanju <br></br>
                  4. ugovor o najmu ( ukoliko je korisnik vodnih usluga
                  najmoprimac ) <br></br> <br></br>
                </div>
              )}

              {documentId === "2" && (
                <div>
                  1. pravomoćno rješenje o izvedenom stanju (za one koji su u
                  postupku legalizacije legalizirali svoje objekte) <br></br>
                  2. rješenje o visini komunalne naknade izdane od jedinice
                  lokalne samouprave (općina ili grad)<br></br> <br></br>
                </div>
              )}

              {documentId === "4" && (
                <div>
                  1. Potvrdu o uplati troškova izvanredne kontrole vodomjera u
                  iznosu od 860,00 kn, uplaćenih na IBAN: HR3924020061100387696.
                  Prilikom uplate u poziv na broj primatelja upisati šifru
                  potrošača š.p.p.. <br></br> <br></br>
                </div>
              )}

              {documentId === "5" && (
                <div>
                  1. Dokaz o vlasništvu, ne stariji od šest mjeseci (može i
                  e-izvadak) <br></br>
                  2. Suglasnost vlasnika nekretnine ukoliko zahtjev podnosi
                  osoba koja nije vlasnik, već zakoniti posjednik nekretnine
                  <br></br>
                  3. Rješenje o upisu u Upisnik obiteljskih poljoprivrednih
                  gospodarstva za obiteljska poljoprivredna gospodarstva (OPG),
                  odnosno u Upisnik poljoprivrednika za samoopskrbna
                  poljoprivredna gospodarstva (SOPG) obrta registriranog za
                  obavljanje poljoprivredne djelatnosti; trgovačkog društva ili
                  zadruge registrirane za obavljanje poljoprivredne djelatnosti{" "}
                  <br></br>
                  4. Zapisnik o evidenciji uporabe poljoprivrednog zemljišta,
                  kojeg izdaje Agencija za plaćanja u poljoprivredi, ribarstvu i
                  ruralnom razvoju ( u daljnjem tekstu: Agencija za plaćanja)
                  nakon upisa poljoprivrednog zemljišta u „ARKOD“ sustav{" "}
                  <br></br>
                  5. Potvrda HAPIH-a, te dokaz o vlasništvu, zakupu,
                  gospodarskog objekta ukoliko se priključak za
                  poljoprivredu/navodnjavanje traži za poslove stočarstva{" "}
                  <br></br>
                  6. Vremenski period korištenja navodnjavanja tijekom godine{" "}
                  <br></br> <br></br>
                </div>
              )}

              {documentId === "6" && (
                <div>
                  1. Osobna iskaznica <br></br>
                  2. Kartica tekućeg/žiro računa
                  <br></br> <br></br>
                </div>
              )}

              {(documentId === "1" || documentId === "2") && (
                <div>
                  DOSTAVITI JEDAN OD NAVEDENIH DOKUMENATA <br></br>
                </div>
              )}

              {documentId === "1" && (
                <Styled.TextInRed>
                  Prije promjene korisnika vodnih usluga potrebno podmiriti sva
                  dospjela dugovanja, u suprotnom promjena neće biti izvršena.
                </Styled.TextInRed>
              )}
            </div>
            <div>
              <Styled.DropdownLabel>
                {l10n.requests.dragnDropTitle}
              </Styled.DropdownLabel>

              <Styled.Dropzone>
                <Dropzone
                  onDrop={onFilesDropped}
                  maxFiles={10}
                  maxSize={4000000}
                  accept="image/jpeg, image/jpg, image/png, .pdf"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div id="dz-id" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Styled.DropzoneContent>
                        <Styled.DropzoneHeader>
                          <Styled.DropzoneHeaderTitle>
                            <span>{l10n.requests.dropzoneTitle}</span>
                          </Styled.DropzoneHeaderTitle>
                          <Styled.DropzoneHeaderSubtitle>
                            {l10n.requests.dropzoneSubtitle}
                          </Styled.DropzoneHeaderSubtitle>
                        </Styled.DropzoneHeader>
                        {files.length > 0 && (
                          <Styled.DropzoneFiles>
                            {files.map((file, index) =>
                              renderFile(file, index)
                            )}
                          </Styled.DropzoneFiles>
                        )}
                      </Styled.DropzoneContent>
                    </div>
                  )}
                </Dropzone>
              </Styled.Dropzone>
            </div>
            <Styled.ButtonAtBottomMobile isMobile={!isBrowser}>
              <Button type="submit" color="primary" onClick={() => onUpload()}>
                {l10n.requests.pdfPreview}
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={() => handleModalOpen()}
              >
                {l10n.requests.button}
              </Button>
            </Styled.ButtonAtBottomMobile>
          </Styled.Content>
        </Col>
      </Row>
      {renderUploadModal()}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    documents: state.documents,
    requests: state.requests,
    meters: state.meters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDocumentPDFFields: (
      documentName,
      documentId,
      meterSiteCode,
      meterCode
    ) =>
      dispatch(
        getDocumentPDFFields(documentName, documentId, meterSiteCode, meterCode)
      ),
    getDocumentPDFFieldsUpdated: (
      documentName,
      documentId,
      meterSiteCode,
      meterCode
    ) =>
      dispatch(
        getDocumentPDFFieldsUpdated(
          documentName,
          documentId,
          meterSiteCode,
          meterCode
        )
      ),
    getDocumentTypes: () => dispatch(getDocumentTypes()),
    getDocumentPDF: (documentName, documentId, fields) =>
      dispatch(getDocumentPDF(documentName, documentId, fields)),
    getMeters: () => dispatch(getMeters()),
    saveUserDocuments: (data) => dispatch(saveUserDocuments(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsForm);
