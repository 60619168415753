import * as Joi from "joi-browser";

const schema = Joi.object().keys({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  company: Joi.string().allow(""),
  vat: Joi.string(),
  userCode: Joi.string().required(),
  email: Joi.string()
    .email({ minDomainSegments: 2 })
    .required(),
  password: Joi.string()
    .min(6)
    .required(),
  passwordConfirm: Joi.string()
    .valid(Joi.ref("password"))
    .required(),
  policy: Joi.bool()
    .invalid(false)
    .required()
});

export default formData =>
  Joi.validate(formData, schema, { abortEarly: false }, err => {
    if (!err) return null;
    let validationData = {
      firstName: null,
      lastName: null,
      company: null,
      vat: null,
      userCode: null,
      email: null,
      password: null,
      passwordConfirm: null,
      policy: null
    };

    err.details.map(error => (validationData[error.path[0]] = error.message));

    return validationData;
  });
