import React from "react";
import * as Styled from "./CallBlock.styles";
import l10n from "../../../../localization";
import { Row } from "reactstrap";

export default function CallBlock() {
  return (
    <Styled.Card>
      <Styled.Header>{l10n.help.phoneContact}</Styled.Header>
      <Styled.Body>
        <Row>
          <Styled.BlockCol>
            <Styled.Title>{l10n.help.callBlock1}</Styled.Title>
            <Styled.link href="mailto:mojvodovod@vodovod-labin.hr">
              mojvodovod@vodovod-labin.hr
            </Styled.link>
            <br />
            <Styled.link href="tel:+385(52)855155">
              Tel: 052/855-155
            </Styled.link>
          </Styled.BlockCol>
          <Styled.BlockCol>
            <Styled.Title>{l10n.help.callBlock2}</Styled.Title>
            <Styled.link href="mailto:mojvodovod@vodovod-labin.hr">
              mojvodovod@vodovod-labin.hr
            </Styled.link>
            <br />
            <Styled.link href="tel:+385(52)529934">
              Tel: 052/529-934
            </Styled.link>
          </Styled.BlockCol>
          <Styled.BlockCol>
            <Styled.Title>{l10n.help.callBlock3}</Styled.Title>
            <Styled.link href="mailto:mojvodovod@vodovod-labin.hr">
              mojvodovod@vodovod-labin.hr
            </Styled.link>
            <br />
            <Styled.link href="tel:+385(52)529934">
              Tel: 052/529-934
            </Styled.link>
            <br />
            <Styled.link href="tel:+385(52)529934">
              Tel: 052/529-934
            </Styled.link>
          </Styled.BlockCol>
        </Row>
      </Styled.Body>
    </Styled.Card>
  );
}
