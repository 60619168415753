import styled from "styled-components";

export const ProfileDropdown = styled.div`
  position: absolute;
  min-width: 340px;
  top: 61px;
  right: -30px;
  background: ${(props) => props.theme.colors[0]};
  z-index: 9998;
  box-shadow: 0 2px 10px 0 ${(props) => props.theme.colors[20]};
  display: ${(props) => (props.visible ? "block" : "none")};
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  border-radius: 3px;
`;

export const ArrowTop = styled.div`
  position: absolute;
  top: 38px;
  right: -30px;
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 9999;
  > i {
    font-size: 30px;
    color: ${(props) => props.theme.colors[0]};
  }
`;

export const UserBlock = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  padding: 20px 25px;
  > [title="user"],
  > [title="email"] {
    font-family: Roboto;
    font-style: normal;
    font-weight: ${(props) => props.theme.font.regular.weight};
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[18]};
    transform: none;
  }
`;

export const Submenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    &:first-of-type {
      margin: 15px 0 0;
    }

    &:last-of-type {
      margin: 0 0 10px;
    }

    svg {
      margin: 0 20px 0 0;
    }

    a {
      display: flex;
      flex-flow: row wrap;
      padding: 15px 25px;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: ${(props) => props.theme.colors[18]};
        transition: 0.2s color ease-in;
      }

      &:hover {
        text-decoration: none;
        span {
          color: ${(props) => props.theme.colors[19]};
        }

        .fill path {
          fill: ${(props) => props.theme.colors[19]};
        }
      }
    }

    .fill path {
      transition: 0.2s fill ease-in;
      fill: ${(props) => props.theme.colors[18]};
    }
  }
`;

export const Devider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors[4]};
`;
