import { Types } from "../../actions/contact/sendEnquiry.actions";

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: null
};

export default function sendEnquiry(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SEND_ENQUIRY: {
      return {
        ...state,
        ...INITIAL_STATE,
        loading: true
      };
    }

    case Types.SEND_ENQUIRY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case Types.SEND_ENQUIRY_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }

    case Types.SEND_ENQUIRY_RESET: {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
}
