import styled from "styled-components";
import { Modal } from "reactstrap";

export default {
  ModalHolder: styled(Modal)`
    max-width: 70vw;
    @media (max-width: 800px) {
      max-width: 95vw;
    }
  `
};
