import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: ${(props) => (props.wrapperWidth ? props.wrapperWidth : "auto")};

  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
  @media print {
    display: none;
  }
`;

export const Dropdown = styled.div`
  color: ${(props) => props.theme.colors[19]};
  padding: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  > i {
    margin: 0 0 0 5px;
    transition: 0.2s all ease-in;
    ${(props) =>
      props.opened &&
      css`
        transform: rotate(180deg);
      `};
  }
  user-select: none;
`;

export const Options = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 45px;
  left: 0;
  background: ${(props) => props.theme.colors[0]};
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
  width: auto;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "150px")};
  border-radius: 3px;
  z-index: 9999;
  overflow-x: hidden;
  display: ${(props) => (props.opened ? "block" : "none")};
  max-height: 70vh;
  overflow-y: scroll;
  > li {
    > span {
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      text-decoration: none;
      color: ${(props) => props.theme.colors[10]};
      display: block;
      padding: 10px 15px;
      cursor: pointer;
      &:hover,
      &.active {
        transition: 0.2s all ease-in;
        background-color: ${(props) => props.theme.colors[12]};
        color: ${(props) => props.theme.colors[0]};
      }
    }
  }
`;
