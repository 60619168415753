import React from "react";
import * as Styled from "./Sidebar.styles";
import Logo from "../Logo/Logo";
import SideNavigation from "./_components/SideNavigation";

export default function Sidebar(props) {
  return (
    <Styled.Sidebar open={props.mobileOpen}>
      <Styled.SidebarLogo to="/">
        <Logo />
      </Styled.SidebarLogo>
      <SideNavigation onNavigationChange={props.onNavigationChange} />
    </Styled.Sidebar>
  );
}
