import React from "react";
import PersonalDataForm from "./_components/PersonalDataForm";
import * as Styled from "./profile.styles";
import { connect } from "react-redux";
import { getUserInfo } from "../../redux/actions/user/user.actions";
import l10n from "../../localization";

const Profile = ({ user }) => {
  return (
    <React.Fragment>
      <Styled.Header>
        <Styled.Title type="h1">{l10n.profile.profile}</Styled.Title>
        <Styled.Code>
          <Styled.CodeText>{l10n.profile.userCode}</Styled.CodeText>
          <Styled.CodeValue>{user.userData.data.userCode}</Styled.CodeValue>
        </Styled.Code>
      </Styled.Header>

      <PersonalDataForm />
    </React.Fragment>
  );
};

export default connect(({ user }) => ({ user }), { getUserInfo })(Profile);
