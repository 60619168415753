import { Types } from "../actions/notification.actions";

const INITIAL_STATE = {
  result: [],
  error: "",
  loaded: false
};

export default function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        result: action.payload,
        loaded: true
      };
    }

    case Types.NOTIFICATION_ERROR: {
      return {
        ...state,
        error: action.payload.error
      };
    }

    default:
      return state;
  }
}
