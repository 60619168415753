import * as Joi from "joi-browser";

const schema = Joi.object().keys({
  email: Joi.string()
    .email({ minDomainSegments: 2 })
    .required()
});

export default formData =>
  Joi.validate(formData, schema, { abortEarly: false }, err => {
    if (!err) return null;
    let validationData = {
      email: null
    };

    err.details.map(error => (validationData[error.path[0]] = error.message));

    return validationData;
  });
