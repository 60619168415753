import styled from "styled-components";

export const Checkbox = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border: 1px solid ${(props) => props.theme.colors[19]};
  border-radius: 3px;
  background-color: ${(props) =>
    props.enabled ? (props) => props.theme.colors[19] : "transparent"};
  cursor: pointer;
  margin: 0 0 0 10px;
`;

export const OptionText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  display: inline-block;
  color: ${(props) => props.theme.colors[27]};
`;

export const InputWrap = styled.div`
  display: inline-block;
  vertical-align: middle;

  &:not(:first-of-type) {
    margin: 0 0 20px 25px;
  }

  &:first-of-type {
    margin: 0 0 20px 0;
  }

  @media (max-width: 1024px) {
    &:not(:first-of-type) {
      margin: 0 0 20px 0;
      width: 100%;
    }
    &:first-of-type {
      margin: 0 0 20px 0;
      width: 100%;
    }
  }
`;
