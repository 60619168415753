import React from "react";
import { CardBody } from "reactstrap";
import Text from "../../components/Text";
import DefaultTheme from "../../theme/default";
import * as Styled from "./guides.styles";

export default function Guides(props) {
  return (
    <div>
      <Text type="h1">Guides</Text>
      <Styled.Card>
        <Text type="h2">Colors</Text>
        <CardBody>
          <Styled.Colors>
            {DefaultTheme.colors.map((color, index) => (
              <li key={index}>
                <Styled.ColorBlock color={index}>{index}</Styled.ColorBlock>
              </li>
            ))}
          </Styled.Colors>
        </CardBody>
      </Styled.Card>

      <Styled.Card>
        <Text type="h2">Text</Text>
        <CardBody>
          <Text type="h1">This is text header1</Text>
          <Text type="h2">This is text header2</Text>
          <Text type="h3">This is text header3</Text>
          <Text type="h4">This is text header4</Text>
          <Text type="h5">This is text header5</Text>
          <Text>This is text paragraph</Text>
          <Text>This is text paragraph</Text>
        </CardBody>
      </Styled.Card>
    </div>
  );
}
