import React from "react";
import * as Styled from "./Logo.styles";
import LogoImage from "../../../assets/logo-vodovod-labin.svg";

export default function Logo() {
  return (
    <Styled.Logo background-color={"#ffffff"}>
      <img src={LogoImage} alt="VODOVOD LABIN" />
      {/* <Styled.Title>VODOVOD LABIN</Styled.Title> */}
    </Styled.Logo>
  );
}
