import axios from "axios";

export const onlineInvoice = userId => {
  return axios.post(`/user/${userId}/online-invoice`, {
    enabled: true
  });
};

export const onlineNotification = userId => {
  return axios.post(`/user/${userId}/online-notification`, {
    enabled: true
  });
};

export const emailInvoice = userId => {
  return axios.post(`/user/${userId}/email-invoice`, {
    enabled: true
  });
};
