import React from "react";
import { connect } from "react-redux";
import l10n from "../../../../localization";
import { Row, Col } from "reactstrap";
import * as Styled from "./GraphList.styles";
import LineGraph from "../../../../components/LineGraph";

const GraphList = ({ data, startDate, endDate }) => {
  const renderCards = (meter) => {
    return (
      <React.Fragment key={`${meter.meterSiteCode}_main`}>
        {meter.meters.map((item) => (
          <Col key={`${item.meterCode}_reading`} md="4">
            <Styled.Card>
              <Styled.CardHeader>
                <Styled.HeaderTop>
                  <Styled.HeaderLegend>
                    <Styled.HeaderLegendText>
                      {l10n.graphList.consumption}
                    </Styled.HeaderLegendText>
                  </Styled.HeaderLegend>
                  <Styled.HeaderData>
                    <Styled.HeaderText>{meter.address}</Styled.HeaderText>
                    <Styled.HeaderText>{`${meter.meterSiteCode} - Vodomjer: ${item.meterCode}`}</Styled.HeaderText>
                  </Styled.HeaderData>
                </Styled.HeaderTop>
              </Styled.CardHeader>
              <Styled.CardChart>
                <LineGraph
                  startDate={startDate}
                  endDate={endDate}
                  readings={item.readings}
                  type="readings"
                />
              </Styled.CardChart>
            </Styled.Card>
          </Col>
        ))}
        <Col key={`${meter.meterSiteCode}_invoices`} md="4">
          <Styled.Card>
            <Styled.CardHeader>
              <Styled.HeaderTop>
                <Styled.HeaderLegend>
                  <Styled.HeaderLegendText>
                    {l10n.graphList.value}
                  </Styled.HeaderLegendText>
                </Styled.HeaderLegend>
                <Styled.HeaderData>
                  <Styled.HeaderText>{meter.address}</Styled.HeaderText>
                  <Styled.HeaderText>{meter.meterSiteCode}</Styled.HeaderText>
                </Styled.HeaderData>
              </Styled.HeaderTop>
            </Styled.CardHeader>
            <Styled.CardChart>
              <LineGraph
                startDate={startDate}
                endDate={endDate}
                readings={meter.invoices}
                type="invoices"
              />
            </Styled.CardChart>
          </Styled.Card>
        </Col>
      </React.Fragment>
    );
  };

  return (
    <Row>
      {data && data.length > 0 ? (
        data.map((meter) => renderCards(meter))
      ) : (
        <Col>
          <p>{l10n.reports.noResults}</p>
        </Col>
      )}
    </Row>
  );
};

export default connect(({ meters }) => ({ meters }), null)(GraphList);
