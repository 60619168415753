import styled from "styled-components";

export const Header = styled.div`
  background: ${(props) => props.theme.colors[0]};
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
  border-radius: 4px;
  padding: 13px 24px;
  min-height: 100px;
  margin: 0 0 20px;

  h1 {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[23]};
    margin: 0 0 5px;
  }

  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[18]};
  }
`;

export const HeaderHelpMessage = styled.p`
  margin-bottom: 0;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors[18]};
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 20px;
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.colors[18]};
  }
`;
