import * as Joi from "joi-browser";

const schema = Joi.object().keys({
  password: Joi.string()
    .min(6)
    .required(),
  confirmPassword: Joi.string()
    .valid(Joi.ref("password"))
    .required()
});

export default formData =>
  Joi.validate(formData, schema, { abortEarly: false }, err => {
    if (!err) return null;
    let validationData = {
      password: null
    };

    err.details.map(error => (validationData[error.path[0]] = error.message));

    return validationData;
  });
