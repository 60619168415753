import React from "react";
import moment from "moment";
import l10n from "../../../../../../localization";
import * as Styled from "./mobileMeterInvoiceEntry.styles";
import Helpers from "../../../../../../helpers/helpers";

const MobileMeterInvoiceEntry = (props) => {
  const { meter } = props;

  if (!meter) return;
  return (
    <Styled.Wrapper>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.invoices.measurementPoint}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>{meter.address}</Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.invoices.meterCode}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>{meter.meterCode}</Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>
          {l10n.invoices.readingDate}
        </Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {moment(meter.readingDate).format("DD.MM.YYYY")}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>{l10n.invoices.reading}</Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>{meter.reading}</Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
      <Styled.MeterInvoiceRow>
        <Styled.MeterInvoiceKey>{l10n.invoices.sum}</Styled.MeterInvoiceKey>
        <Styled.MeterInvoiceValue>
          {Helpers.formatNumber(meter.consumption)}
        </Styled.MeterInvoiceValue>
      </Styled.MeterInvoiceRow>
    </Styled.Wrapper>
  );
};

export default MobileMeterInvoiceEntry;
