import { combineReducers } from "redux";
import activateUser from "./activateUser.reducer";
import resendUserActivationMail from "./resendUserActivationMail.reducer";
import changePassword from "./changePassword.reducer";
import passwordRecovery from "./passwordRecovery.reducer";
import resetPassword from "./resetPassword.reducer";
import registerUser from "./registerUser.reducer";
import sendEnquiry from "./sendEnquiry.reducer";
import userProfile from "./userProfile.reducer";
import userData from "./user.reducer";

export default combineReducers({
  activateUser,
  resendUserActivationMail,
  changePassword,
  passwordRecovery,
  resetPassword,
  registerUser,
  sendEnquiry,
  userProfile,
  userData
});
