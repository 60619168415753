import axios from "axios/index";

export const getUserMeters = (userId) => {
  return axios.get(`/user/${userId}/meter`);
};

export const newMeterReading = ({
  userId,
  meterSiteCode,
  meterCode,
  readingDate,
  value,
}) => {
  return axios.post(`/user/${userId}/meter-reading`, {
    meterSiteCode,
    meterCode,
    readingDate,
    value,
  });
};

export const newMeterReadingChildren = ({
  userId,
  meterSiteCode,
  meterCode,
  readingDate,
  value,
  meterChildrenInputs,
}) => {
  return axios.post(`/user/${userId}/meter-reading-children`, {
    meterSiteCode,
    meterCode,
    readingDate,
    value,
    meterChildrenInputs,
  });
};

export const meterReadingsByDay = ({
  userId,
  meterSiteCode,
  meterCode,
  dateFrom,
  dateTo,
}) => {
  let baseUrl = `/user/${userId}/meter-readings-by-day?`;

  if (meterSiteCode !== "all") {
    baseUrl += `MeterSiteCode=${meterSiteCode}&`;
  }

  if (meterCode !== "all") {
    baseUrl += `MeterCode=${meterCode}&`;
  }

  return axios.get(
    `${baseUrl}DateFrom=${dateFrom}${dateTo ? `&DateTo=${dateTo}` : ""}`
  );
};

export const enableMeterManualReading = ({ userId, meterId }) => {
  return axios.post(`/user/${userId}/meter/${meterId}/enable-manual-reading`, {
    enabled: true,
  });
};
