import styled from "styled-components";

export const ActionHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const MoreInfoWrapper = styled.div`
  align-items: center;
  display: flex;

  p {
    margin: 0;
    margin-right: 10px;
  }
`;
