import React, { useState, useEffect } from "react";
import Styled from "./ContactForm.styles";
import { connect } from "react-redux";
import {
  sendEnquiry,
  sendEnquiryReset
} from "../../../../redux/actions/contact/sendEnquiry.actions";
import Loader from "../../../../components/Loader";

import { Form, FormGroup, Label, Col, Input, Button } from "reactstrap";

import validation from "./ContactForm.validation";
import l10n from "../../../../localization";

function ContactForm(props) {
  const initialMessageState = { message: "" };
  const [message, setMessage] = useState(initialMessageState);
  const [validate, setValidation] = useState({ ...initialMessageState });

  useEffect(() => {
    return () => {
      props.sendEnquiryReset();
    };
    //eslint-disable-next-line
  }, []);

  const sendForm = e => {
    e.preventDefault();
    const validateResponse = validation(message);
    if (!validateResponse) {
      props.sendEnquiry(message.message);
      setValidation(initialMessageState);
      return;
    }

    setValidation(validateResponse);
  };

  const { sendEnquiry } = props.user;

  return (
    <Styled.Card>
      <Styled.HeaderWrapper>
        <Styled.Title>{l10n.help.help}</Styled.Title>
        <Styled.Header>{l10n.help.forAllQuestions}</Styled.Header>
      </Styled.HeaderWrapper>
      <Styled.Body>
        {sendEnquiry.success && <p>{l10n.help.formSuccessSent}!</p>}
        {!sendEnquiry.success && (
          <Form onSubmit={sendForm}>
            <FormGroup row>
              <Col md={12}>
                <Label for="exampleText">{l10n.help.yourMessage}</Label>
              </Col>
              <Col md={12}>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  value={message.message}
                  invalid={!!validate.message}
                  onChange={e =>
                    setMessage({ ...message, message: e.target.value })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={12}>
                {sendEnquiry.loading ? (
                  <Loader inline={true} />
                ) : (
                  <Button type="submit" color="primary">
                    {l10n.help.sendButton}
                  </Button>
                )}
              </Col>
            </FormGroup>
          </Form>
        )}
      </Styled.Body>
    </Styled.Card>
  );
}

export default connect(({ user }) => ({ user }), {
  sendEnquiry,
  sendEnquiryReset
})(ContactForm);
