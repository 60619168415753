import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Styled from "./InvoiceListDesktop.styles";
import { Col, Row } from "reactstrap";
import l10n from "../../../../localization";
import InvoiceEntry from "./_components/InvoiceEntry";
import PayPopup from "../../../PayPopup/PayPopup";
import ReactPaginate from "react-paginate";

const InvoiceListDesktop = ({
  title,
  invoices,
  openSum,
  gatewayWSPAYForm,
  show2dCode,
  onOpenInvoice,
  openInvoices,
}) => {
  const [paginatedInvoices, setPaginatedInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const pageLimit = 24;

  useEffect(() => {
    setCurrentPage(0);
  }, []);

  useEffect(() => {
    setNumberOfPages(
      Math.ceil(invoices && invoices.length > 0 ? invoices.length / 24 : 0)
    );

    const filtered = invoices.slice(
      currentPage * pageLimit,
      currentPage * pageLimit + pageLimit
    );

    setPaginatedInvoices(filtered);
  }, [invoices, currentPage]);

  return (
    <Styled.Card>
      <Styled.Header>
        <Row className="align-items-center">
          <Col md={8}>
            <Styled.Title>{title}</Styled.Title>
          </Col>
          {openSum > 0 && (
            <Col align="right">
              <PayPopup
                invoices={invoices.filter(
                  (invoice) => invoice.isPayed === "Open"
                )}
                buttonLabel={l10n.invoices.pay}
                payAllInvoices={true}
              />
            </Col>
          )}
        </Row>
      </Styled.Header>
      <Styled.Table>
        <thead>
          <tr>
            <th>{l10n.invoices.meterLocation}</th>
            <th>{l10n.invoices.invoiceNumber}</th>
            <th>{l10n.invoices.issueDate}</th>
            <th>{l10n.invoices.expirationDate}</th>
            <th>{l10n.invoices.invoiceValue}</th>
            <th>{l10n.invoices.invoiceBalance}</th>
            <th>{l10n.invoices.invoiceDebt}</th>
            <th>{l10n.invoices.pdf}</th>
            <th>{l10n.invoices.twodcode}</th>
            <th>{l10n.invoices.payOnline}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedInvoices.length === 0 && (
            <tr>
              <td colSpan="11">
                {openInvoices
                  ? l10n.invoices.noOpenInvoices
                  : l10n.invoices.noInvoices}
              </td>
            </tr>
          )}
          {(paginatedInvoices || []).map((invoice, invoiceIndex) => (
            <InvoiceEntry
              key={`${invoice.invoiceId}_${invoiceIndex}`}
              invoice={invoice}
              gatewayWSPAYForm={gatewayWSPAYForm}
              onOpenInvoice={onOpenInvoice}
              show2dCode={show2dCode}
            />
          ))}
        </tbody>
      </Styled.Table>

      {numberOfPages > 1 && (
        <Styled.Pagination>
          <ReactPaginate
            initialPage={currentPage}
            pageCount={numberOfPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            previousLabel="<"
            nextLabel=">"
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
            onPageChange={({ selected }) => setCurrentPage(selected)}
          />
        </Styled.Pagination>
      )}
    </Styled.Card>
  );
};

InvoiceListDesktop.propTypes = {
  openInvoices: PropTypes.bool,
};

InvoiceListDesktop.defaultProps = {
  openInvoices: false,
};

export default InvoiceListDesktop;
