import React from "react";
import * as Styled from "./LogoColumn.styles";
import LogoImage from "../../assets/logo-vodovod-labin.svg";
import AppStoreImage from "../../assets/app_store.png";
import GPlayStoreImage from "../../assets/gplay_store.png";

import l10n from "../../localization";

const LogoColumn = () => {
  return (
    <Styled.Info>
      <Styled.Logo>
        <img src={LogoImage} alt="vodovod labin" />
      </Styled.Logo>

      <h2>{l10n.login.infoTitle}</h2>
      <a href="https://moj.vodovod-labin.hr">{l10n.login.infoLink}</a>
      <Styled.Spacer />
      <Styled.Footer>
        <Styled.FooterText>{l10n.login.footerText}</Styled.FooterText>
        <Styled.Stores>
          <Styled.StoreItem>
            <a
              href="https://apps.apple.com/us/app/moj-vodovod-labin/id6475323506"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStoreImage} alt="Apple App Store" />
            </a>
          </Styled.StoreItem>
          <Styled.StoreItem>
            <a
              href="https://play.google.com/store/apps/details?id=com.labinvodovodimobile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GPlayStoreImage} alt="Google Play Store" />
            </a>
          </Styled.StoreItem>
        </Styled.Stores>
      </Styled.Footer>
    </Styled.Info>
  );
};

export default LogoColumn;
