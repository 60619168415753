import { call, fork, takeLatest, put, select } from "redux-saga/effects";
import { logoutUser } from "../actions/user/user.actions";
import * as actions from "../actions/TwoDCode.actions";
import * as api from "../api/TwoDCode.api";

function* get2dCode({ payload }) {
  try {
    const state = yield select();
    const response = yield call(api.get2dCode, {
      invoiceId: payload.invoiceId,
      userId: state.user.userData.data.userId
    });
    yield put(actions.get2dCodeSuccess(response.data));
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.get2dCodeError({
        error: "An error occurred when trying to get invoices"
      })
    );
  }
}

function* watchGet2dCodeRequest() {
  yield takeLatest(actions.Types.GET_2D_CODE, get2dCode);
}

export default [fork(watchGet2dCodeRequest)];
