import React from "react";
import moment from "moment";
import Helpers from "../../../../../../helpers/helpers";

export default ({ readings, meterCode }) => {
  if (readings.length <= 0) return null;

  return readings.map((meterReading, index) => (
    <tr key={`${meterCode}_${index}`}>
      <td>{moment(meterReading.date).format("DD.MM.YYYY")}</td>
      <td>{meterReading.reading}</td>
      <td>{Helpers.formatNumber(meterReading.consumption)}</td>
    </tr>
  ));
};
