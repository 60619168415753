export const Types = {
  GET_USER_DOCUMENTS: "request/get_user_documents",
  GET_USER_DOCUMENTS_SUCCESS: "request/get_user_documents_success",
  GET_USER_DOCUMENTS_ERROR: "request/get_user_documents_error",
  SAVE_USER_DOCUMENTS: "request/save_user_documents",
  SAVE_USER_DOCUMENTS_SUCCESS: "request/save_user_documents_success",
  SAVE_USER_DOCUMENTS_ERROR: "request/save_user_documents_error",
};

export const getUserDocuments = () => ({ type: Types.GET_USER_DOCUMENTS });

export const getUserDocumentsSuccess = (data) => ({
  type: Types.GET_USER_DOCUMENTS_SUCCESS,
  payload: data,
});

export const getuserDocumentsError = (error) => ({
  type: Types.GET_USER_DOCUMENTS_ERROR,
  payload: error,
});

export const saveUserDocuments = (data) => ({
  type: Types.SAVE_USER_DOCUMENTS,
  payload: data,
});

export const saveUserDocumentsSuccess = (data) => ({
  type: Types.SAVE_USER_DOCUMENTS_SUCCESS,
  payload: data,
});

export const saveUserDocumentsError = (error) => ({
  type: Types.SAVE_USER_DOCUMENTS_ERROR,
  payload: error,
});
