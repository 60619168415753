import styled from "styled-components";
import { Modal } from "reactstrap";

export const ModalHolder = styled(Modal)`
  max-width: 70vw;
  @media (max-width: 800px) {
    max-width: 95vw;
  }
`;

export const HeaderTitle = styled.p`
  color: #1b4267;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 30px;
`;

export const Content = styled.div`
  margin: 10px 15px;
`;

export const Dropdown = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const DropdownLabel = styled.p`
  color: #6a707e;
  font-size: 15px;
  letter-spacing: 0.02em;
  line-height: 22px;
  margin: 0;
  padding: 0;
`;

export const Dropzone = styled.div`
  background-color: #f5f6f8;
  max-height: 75vh;
  overflow-y: scroll;
  padding: 20px;
  width: 100%;

  #dz-id {
    outline: none;
  }
`;

export const DropzoneContent = styled.div`
  height: 100%;
`;

export const DropzoneHeader = styled.div`
  align-items: center;
  border: ${(props) => (props.dragActive ? "1px dashed black" : "none")};
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  font-size: 15px;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 22px;
  padding: 15px 0;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const DropzoneHeaderTitle = styled.div``;

export const DropzoneHeaderSubtitle = styled.div``;

export const DropzoneFiles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const DropzoneFile = styled.div`
  align-items: center;
  background: rgba(196, 196, 196, 0.2);
  display: flex;
  height: 64px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 25px;
  width: 100%;
`;

export const FileTitle = styled.p`
  flex-grow: 1;
  margin: 0;
  padding: 0;
`;

export const FileDelete = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;

  &:hover {
    cursor: pointer;
  }

  img {
    object-fit: contain;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  width: 100%;
`;

export const FooterSend = styled.div`
  button {
    padding: 4px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    height: 40px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;
    width: 100px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors[21]};
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
