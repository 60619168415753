import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getInvoicePdf,
  markInvoiceAsPayed,
  payAllOnline,
} from "../../redux/actions/invoice.actions";
import l10n from "../../localization";
import * as qs from "query-string";
import Modal from "../Modal";
import { isBrowser } from "react-device-detect";
import InvoiceListMobile from "./_components/InvoiceListMobile";
import InvoiceListDesktop from "./_components/InvoiceListDesktop";
import Loader from "../../components/Loader";
import { get2dCode } from "../../redux/actions/TwoDCode.actions";
import PayPopup from "../../components/PayPopup";

const InvoiceList = (props) => {
  const [openSum, setOpenSum] = useState(0);
  const [transactionResponse, setTransactionResponse] = useState({});
  const [twoDCodeModal, setTwoDCodeModal] = useState(false);
  const [twoDCode, setTwoDCode] = useState(false);

  useEffect(() => {
    // parse WSPay transaction
    const queryString = qs.parse(props.location.search);
    if (queryString.transaction) {
      let transactionMessage = "";
      switch (queryString.transaction) {
        case "error":
          transactionMessage =
            l10n.invoices.paymentError + queryString.ErrorMessage;
          break;
        case "success":
          transactionMessage = generateSuccessPayedMessage();
          break;
        case "canceled":
          transactionMessage = l10n.invoices.paymentCanceled;
          break;
        default:
          transactionMessage = l10n.invoices.paymentUnknown;
      }
      setTransactionResponse({
        visible: true,
        message: transactionMessage,
      });

      if (queryString.ShoppingCartID && queryString.WsPayOrderId) {
        if (queryString.All === "1") {
          props.payAllOnline(
            queryString.ShoppingCartID,
            queryString.WsPayOrderId
          );
        } else {
          props.markInvoiceAsPayed(
            queryString.ShoppingCartID,
            queryString.WsPayOrderId
          );
        }
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let debtValue = 0;

    if (props.user && props.user.userData && props.user.userData.data) {
      debtValue = props.user.userData.data.waterServicesDebt || 0;
    }
    setOpenSum(debtValue);
  }, [props.user]);

  useEffect(() => {
    if (props.twoDCode.success) setTwoDCode(props.twoDCode.data);
  }, [props.twoDCode]);

  const openPDFInvoice = (invoiceId) => {
    props.getInvoicePdf(invoiceId);
  };

  const show2dCode = (invoiceId) => {
    props.get2dCode(invoiceId);
    setTwoDCodeModal(true);
  };

  const gatewayWSPAYForm = (invoice) => {
    return (
      invoice.isPayed &&
      invoice.isPayed === "Open" && (
        <PayPopup invoices={[invoice]} buttonLabel={l10n.invoices.pay} />
      )
    );
  };

  const generateSuccessPayedMessage = () => {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          <strong style={{ color: "#28a745" }}>
            {l10n.invoices.paymentSuccessTitle}
          </strong>
          <p style={{ marginTop: "20px" }}>
            {l10n.invoices.paymentProcessingMessage1}
          </p>
        </p>
      </div>
    );
  };

  if (!props.loaded) return <Loader />;

  return (
    <React.Fragment>
      {!isBrowser && (
        <InvoiceListMobile
          title={props.title}
          invoices={props.invoices}
          openInvoices={props.openInvoices}
          openSum={openSum}
          onOpenInvoice={openPDFInvoice}
          gatewayWSPAYForm={gatewayWSPAYForm}
          show2dCode={show2dCode}
        />
      )}
      {isBrowser && (
        <InvoiceListDesktop
          title={props.title}
          invoices={props.invoices}
          openInvoices={props.openInvoices}
          openSum={openSum}
          onOpenInvoice={openPDFInvoice}
          gatewayWSPAYForm={gatewayWSPAYForm}
          show2dCode={show2dCode}
        />
      )}
      <Modal
        open={transactionResponse.visible}
        title={l10n.invoices.paymentModalTitle}
        footer={true}
      >
        {transactionResponse.message}
      </Modal>

      <Modal
        open={twoDCodeModal}
        title={l10n.invoices.twodcode}
        footer={true}
        onClose={() => {
          setTwoDCode(null);
          setTwoDCodeModal(false);
        }}
      >
        {l10n.invoices.twodcodeText}
        <br />
        <div className="text-center">
          {!twoDCode ? <Loader inline /> : <img src={twoDCode} alt="2d-code" />}
        </div>
      </Modal>
    </React.Fragment>
  );
};

InvoiceList.propTypes = {
  openInvoices: PropTypes.bool,
};

InvoiceList.defaultProps = {
  openInvoices: false,
};

export default connect(({ twoDCode, user }) => ({ twoDCode, user }), {
  getInvoicePdf,
  markInvoiceAsPayed,
  get2dCode,
  payAllOnline,
})(withRouter(InvoiceList));
