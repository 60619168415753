import React, { useState } from "react";
import { connect } from "react-redux";
import Helpers from "../../helpers/helpers";
import Styled from "./GatewayWSPAYForm.styles";
import l10n from "../../localization";
import { IconPayBill } from "../../components/Icons/Icons";

function GatewayWSPAYForm(props) {
  const displayUrl = window.location.origin + window.location.pathname;
  const [redirecting, setRedirecting] = useState(false);

  const getUserData = () => {
    if (props.user && props.user.userData && props.user.userData.data) {
      const {
        address,
        email,
        name,
        post,
        postCode,
        telephone,
      } = props.user.userData.data;
      return {
        address: address || "",
        firstName: name && name.split(" ").length > 0 ? name.split(" ")[0] : "",
        lastName: name && name.split(" ").length > 1 ? name.split(" ")[1] : "",
        email: email || "",
        post: post || "",
        postCode: postCode || "",
        phone: telephone || "",
      };
    }

    return null;
  };

  const onSubmit = () => {
    setRedirecting(true);
  };

  if (props.invoice.isPayed === "Open") {
    return (
      <React.Fragment>
        <form
          name="pay"
          action={process.env.REACT_APP_WSPAY_URL}
          method="POST"
          onSubmit={onSubmit}
        >
          <input
            type="hidden"
            name="ShopID"
            value={process.env.REACT_APP_WSPAY_SHOPID}
          />
          <input
            type="hidden"
            name="ShoppingCartID"
            value={props.invoice.invoiceId}
          />
          <input
            type="hidden"
            name="TotalAmount"
            value={Helpers.formatNumber(props.invoice.invoiceValue)}
          />
          <input
            type="hidden"
            name="Signature"
            value={props.invoice.wsPaySignature}
          />
          <input
            type="hidden"
            name="ReturnURL"
            value={`${displayUrl}?transaction=success&All=${props.all ? 1 : 0}`}
          />
          <input
            type="hidden"
            name="CancelURL"
            value={`${displayUrl}?transaction=canceled`}
          />
          <input
            type="hidden"
            name="ReturnErrorURL"
            value={`${displayUrl}?transaction=error`}
          />
          {getUserData() && (
            <>
              <input
                type="hidden"
                name="CustomerFirstName"
                value={getUserData().firstName}
              />
              <input
                type="hidden"
                name="CustomerLastName"
                value={getUserData().lastName}
              />
              <input
                type="hidden"
                name="CustomerAddress"
                value={getUserData().address}
              />
              <input
                type="hidden"
                name="CustomerCity"
                value={getUserData().post}
              />
              <input
                type="hidden"
                name="CustomerZIP"
                value={getUserData().postCode}
              />
              <input
                type="hidden"
                name="CustomerPhone"
                value={getUserData().phone}
              />
              <input
                type="hidden"
                name="CustomerEmail"
                value={getUserData().email}
              />
            </>
          )}
          {props.children ? (
            props.children
          ) : (
            <Styled.button
              color="success"
              disabled={props.disabled || redirecting}
            >
              <IconPayBill></IconPayBill>
              {redirecting && l10n.invoices.loading}
              {!redirecting && l10n.invoices.payOnline}
            </Styled.button>
          )}
        </form>
      </React.Fragment>
    );
  }

  return null;
}

export default connect(({ user }) => ({ user }), null)(GatewayWSPAYForm);
