import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Styled from "./TableList.styles";
import MeterRow from "./_components/MeterRow";
import l10n from "../../../../localization";
import { isBrowser } from "react-device-detect";
import MobileMeterRows from "./_components/MobileMeterRows/MobileMeterRows";
import ReactPaginate from "react-paginate";

const TableList = ({ data, startDate, endDate }) => {
  const [paginatedReports, setPaginatedReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const pageLimit = 24;

  useEffect(() => {
    setCurrentPage(0);
  }, []);

  useEffect(() => {
    setNumberOfPages(Math.ceil(data && data.length > 0 ? data.length / 24 : 0));

    const filtered = data.slice(
      currentPage * pageLimit,
      currentPage * pageLimit + pageLimit
    );

    setPaginatedReports(filtered);
  }, [data, currentPage]);

  const renderTable = (readings) => {
    if (isBrowser) {
      return (
        <Styled.Table>
          <thead>
            <tr border="1">
              <th>{l10n.reports.date}</th>
              <th>{l10n.reports.reading}</th>
              <th>{l10n.reports.consumption}</th>
            </tr>
          </thead>
          <tbody>
            {}
            <MeterRow
              readings={readings}
              startDate={startDate}
              endDate={endDate}
            />
          </tbody>
        </Styled.Table>
      );
    } else {
      return (
        <Styled.CardMobileTable>
          <MobileMeterRows
            readings={readings}
            startDate={startDate}
            endDate={endDate}
          />
        </Styled.CardMobileTable>
      );
    }
  };

  return (
    <>
      {paginatedReports && paginatedReports.length > 0 ? (
        paginatedReports.map((item, meterIndex) =>
          item.meters.map((itemMeter) => (
            <Styled.Card key={`${itemMeter.meterCode}_${meterIndex}`}>
              <Styled.HeaderWrapper>
                <Styled.Header>
                  <Styled.HeaderRow>
                    <Styled.HeaderTitle>
                      {l10n.reports.site_address}
                    </Styled.HeaderTitle>
                    <Styled.HeaderContent>{item.address}</Styled.HeaderContent>
                  </Styled.HeaderRow>
                  <Styled.HeaderRow>
                    <Styled.HeaderTitle>
                      {l10n.reports.meter}
                    </Styled.HeaderTitle>
                    <Styled.HeaderContent>
                      {itemMeter.meterCode}
                    </Styled.HeaderContent>
                  </Styled.HeaderRow>
                </Styled.Header>
              </Styled.HeaderWrapper>

              {renderTable(itemMeter.readings)}
            </Styled.Card>
          ))
        )
      ) : (
        <p>{l10n.reports.noResults}</p>
      )}

      {numberOfPages > 1 && (
        <Styled.Pagination>
          <ReactPaginate
            initialPage={currentPage}
            pageCount={numberOfPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            previousLabel="<"
            nextLabel=">"
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
            onPageChange={({ selected }) => setCurrentPage(selected)}
          />
        </Styled.Pagination>
      )}
    </>
  );
};

export default connect(({ meters }) => ({ meters }), null)(TableList);
