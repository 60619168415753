import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  passwordRecoverySuccess,
  passwordRecoveryError,
  Types
} from "../../actions/user/passwordRecovery.actions";
import * as api from "../../api/user.api";

function* passwordRecovery({ payload }) {
  try {
    yield call(api.passwordRecovery, payload.email);
    yield put(passwordRecoverySuccess());
  } catch (err) {
    yield put(passwordRecoveryError(err.response.data.Messages));
  }
}

function* watchPasswordRecovery() {
  yield takeLatest(Types.PASSWORD_RECOVERY, passwordRecovery);
}

export default [fork(watchPasswordRecovery)];
