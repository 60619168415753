import styled from "styled-components";

export default {
  MetersRow: styled.tr`
    display: ${(props) => (props.visible ? "table-row" : "none")};

    table {
      border: 1px solid ${(props) => props.theme.colors[17]};
      margin: 0 auto;
      width: 100%;
    }
  `,
  HoverElement: styled.div`
    &:hover {
      cursor: pointer;
    }
  `,
  PriceWrapper: styled.td`
    color: ${(props) =>
      props.negative
        ? props.theme.colors[25] + " !important"
        : props.theme.colors[26] + " !important"};
  `,
  InvoiceWrapper: styled.tr`
    cursor: ${(props) => (props.cursorVisible ? "pointer" : "auto")};

    .weight-medium {
      font-weight: 500;
      color: ${(props) => props.theme.colors[23]};
    }
  `,
  IsReversed: styled.td`
    color: ${(props) => props.theme.colors[25]}!important;
    width: 30px;

    span {
      margin-right: 10px;
    }
  `,
};
