import { Types } from "../../actions/user/passwordRecovery.actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: null
};

export default function passwordRecovery(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PASSWORD_RECOVERY: {
      return {
        ...state,
        ...INITIAL_STATE,
        loading: true
      };
    }

    case Types.PASSWORD_RECOVERY_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    }
    case Types.PASSWORD_RECOVERY_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true
      };
    }

    case Types.PASSWORD_RECOVERY_RESET: {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
}
