import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { logoutUser } from "../actions/user/user.actions";
import * as actions from "../actions/requests.actions";
import * as api from "../api/requests.api";

function* getUserDocuments() {
  try {
    const state = yield select();

    const requests = yield call(
      api.getUserDocuments,
      state.user.userData.data.userId
    );
    yield put(actions.getUserDocumentsSuccess(requests.data));
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    if (err.response.data.Messages) {
      yield put(actions.getuserDocumentsError(err.response.data.Messages));
    } else {
      yield put(actions.getuserDocumentsError(err.response.data.messages));
    }
  }
}

function* watchGetUserDocuments() {
  yield takeLatest(actions.Types.GET_USER_DOCUMENTS, getUserDocuments);
}

function* saveUserDocuments({ payload }) {
  try {
    const state = yield select();

    yield call(api.saveUserDocuments, {
      userId: state.user.userData.data.userId,
      data: payload,
    });

    yield put(actions.saveUserDocumentsSuccess());
    //yield put(actions.getUserDocuments());
  } catch (err) {
    if (err.response.status === 401) yield put(logoutUser());
    if (err.response.data.Messages) {
      yield put(actions.saveUserDocumentsError(err.response.data.Messages));
    } else {
      yield put(actions.saveUserDocumentsError(err.response.data.messages));
    }
  }
}

function* watchSaveUserDocuments() {
  yield takeLatest(actions.Types.SAVE_USER_DOCUMENTS, saveUserDocuments);
}

export default [fork(watchGetUserDocuments), fork(watchSaveUserDocuments)];
