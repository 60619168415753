import React from "react";
import * as Styled from "./NotificationDropdown.styles";
import NotificationBlock from "../NotificationBlock";
import { markAllReadNotifications } from "../../../../../../../redux/actions/notification.actions";
import { connect } from "react-redux";
import l10n from "../../../../../../../localization";
import { IconTriangle } from "../../../../../../../components/Icons/Icons";

function NotificationDropdown(props) {
  const showPopup = props.visible;

  const markAllReadNotifications = e => {
    e.stopPropagation();
    props.markAllReadNotifications();
  };

  return (
    <Styled.NotificationWrapper>
      <Styled.ArrowTop visible={showPopup}>
        <IconTriangle />
      </Styled.ArrowTop>
      <Styled.NotificationDropdown visible={showPopup}>
        {props.notifications.length > 0 ? (
          <Styled.Notification>
            <em>
              {l10n.notificationDropdown.youHave} {props.notifications.length}{" "}
            </em>
            {props.notifications.length === 1 ? (
              <em>{l10n.notificationDropdown.notification}</em>
            ) : (
              <em>{l10n.notificationDropdown.notifications}</em>
            )}
            .
          </Styled.Notification>
        ) : (
          <Styled.Notification>
            <em>{l10n.notificationDropdown.noNotifications}</em>
          </Styled.Notification>
        )}
        {props.notifications.length > 0 && (
          <React.Fragment>
            <Styled.NotificationsHolder>
              {(props.notifications || []).map(notification => (
                <NotificationBlock
                  notification={notification}
                  key={notification.notificationId}
                />
              ))}
            </Styled.NotificationsHolder>

            <Styled.ClearWrap>
              <Styled.ClearAll onClick={markAllReadNotifications}>
                {l10n.notificationDropdown.clearAll}
              </Styled.ClearAll>
            </Styled.ClearWrap>
          </React.Fragment>
        )}
      </Styled.NotificationDropdown>
    </Styled.NotificationWrapper>
  );
}

export default connect(null, { markAllReadNotifications })(
  NotificationDropdown
);
