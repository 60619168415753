import React, { useState, useEffect } from "react";
import * as Styled from "./HeaderNotification.styles";
import NotificationDropdown from "./_components/NotificationDropdown";
import { connect } from "react-redux";
import { getNotifications } from "../../../../../redux/actions/notification.actions";
import {
  IconNotificationActive,
  IconNotification
} from "../../../../../components/Icons/Icons";

function HeaderNotification(props) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    props.getNotifications();
    //eslint-disable-next-line
  }, []);

  return (
    <Styled.notificationWrap
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {props.notification.result.length > 0 ? (
        <IconNotificationActive />
      ) : (
        <IconNotification />
      )}
      <NotificationDropdown
        visible={visible}
        notifications={props.notification.result}
      />
    </Styled.notificationWrap>
  );
}

export default connect(({ notification }) => ({ notification }), {
  getNotifications
})(HeaderNotification);
