import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import InvoiceList from "../../components/InvoiceList";
import Text from "../../components/Text";
import {
  getInvoices,
  getInvoicesReset,
} from "../../redux/actions/invoice.actions";
import l10n from "../../localization";
import * as Styled from "./Dashboard.styled";
import { connect } from "react-redux";
import DashboardWidget from "../../components/DashboardWidget";

function Dashboard({ invoices, user, getInvoices, getInvoicesReset }) {
  useEffect(() => {
    getInvoices(false);

    return function cleanup() {
      getInvoicesReset();
    };
    //eslint-disable-next-line
  }, []);

  let waterDebtValue = 0;
  let waterDebtValueEur = 0;
  let balanceValue = 0;
  let balanceValueEur = 0;

  if (user && user.data) {
    const { waterServicesDebt, balance, waterServicesDebtEUR, balanceEUR } = user.data;
    waterDebtValue = waterServicesDebt || 0;
    waterDebtValueEur = waterServicesDebtEUR || 0;
    balanceValue = balance || 0;
    balanceValueEur = balanceEUR || 0;
  }

  const filterInvoices = () => {
    return invoices.result.filter((invoice) => invoice.isPayed === "Open");
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Styled.Header>
            <Row>
              <Col md={12}>
                <Text type={"h1"}>{l10n.dashboard.welcomeTitle}</Text>
                <Text>{l10n.dashboard.welcomeMessage}</Text>
              </Col>
            </Row>
            <Row>
              <Col md={12} align="right">
                <Styled.HeaderHelpMessage>
                  {l10n.dashboard.helpMessage}{" "}
                  <Styled.Link href="mailto:mojvodovod@vodovod-labin.hr">
                    mojvodovod@vodovod-labin.hr
                  </Styled.Link>
                </Styled.HeaderHelpMessage>
              </Col>
            </Row>
          </Styled.Header>
        </Col>
      </Row>
      {invoices.loaded && !invoices.loading && (
        <Row style={{ justifyContent: "flex-end" }}>
          {waterDebtValue !== 0 && (
            <Col xl={{ size: 3 }} md={{ size: 4 }} sm={6} xs={12}>
              <DashboardWidget
                title={l10n.widget.debtStatus}
                value={waterDebtValue}
                valueEur={waterDebtValueEur}
                negative={waterDebtValue > 0}
                formatPrice
              />
            </Col>
          )}

          {balanceValue !== 0 && (
            <Col xl={{ size: 3 }} md={{ size: 4 }} sm={6} xs={12}>
              <DashboardWidget
                title={l10n.widget.overpaymentStatus}
                value={balanceValue}
                valueEur={balanceValueEur}
                formatPrice
              />
            </Col>
          )}
        </Row>
      )}
      <InvoiceList
        title={l10n.dashboard.outstandingInvoices}
        openInvoices={true}
        invoices={filterInvoices()}
        loaded={invoices.loaded}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoices: (isReversed) => dispatch(getInvoices(isReversed)),
    getInvoicesReset: () => dispatch(getInvoicesReset()),
  };
};

export default connect(
  ({ invoices, user: { userData } }) => ({ invoices, user: userData }),
  mapDispatchToProps
)(Dashboard);
