import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  resendUserActivationMailSuccess,
  resendUserActivationMailError,
  Types
} from "../../actions/user/resendUserActivationMail.actions";

import * as api from "../../api/user.api";

function* resendUserActivationMail({ payload }) {
  try {
    yield call(api.resendUserActivationMail, { email: payload.email });
    yield put(resendUserActivationMailSuccess());
  } catch (err) {
    yield put(resendUserActivationMailError(err.response.data.Messages));
  }
}

function* watchResendUserActivationMail() {
  yield takeLatest(
    Types.RESEND_USER_ACTIVATION_EMAIL,
    resendUserActivationMail
  );
}

export default [fork(watchResendUserActivationMail)];
