import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  Alert,
  FormText,
  Container,
  Row,
  Col,
} from "reactstrap";
import * as Styled from "./ResetPassword.styles";
import {
  LoginWrapper,
  FormWrapper,
  GlobalButton,
  GlobalLink,
} from "../../components/LoginComponents/LoginGlobal.styles";

import Text from "../../components/Text";
import { connect } from "react-redux";
import {
  resetPassword,
  resetPasswordReset,
} from "../../redux/actions/user/resetPassword.actions";

import LogoColumn from "../../components/LoginComponents/LogoColumn";

import validation from "./ResetPassword.validation";
import l10n from "../../localization";

function ResetPassword(props) {
  const INITIAL_PASSWORD_STATE = {
    password: "",
    confirmPassword: "",
  };
  const [password, setPassword] = useState(INITIAL_PASSWORD_STATE);
  const [validate, setValidation] = useState(INITIAL_PASSWORD_STATE);

  useEffect(() => {
    return () => {
      props.resetPasswordReset();
    };
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const target = e.target;
    setPassword({
      ...password,
      [target.name]: target.value,
    });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    const validateResponse = validation(password);
    if (!validateResponse) {
      props.resetPassword({
        password: password.password,
        passwordRecoveryProcedureId: props.match.params.prpid,
      });
      setValidation(INITIAL_PASSWORD_STATE);
      return;
    }

    setValidation(validateResponse);
  };

  const completed = !!props.user.resetPassword.success;

  return (
    <LoginWrapper>
      <Container>
        <Row noGutters={true}>
          <Col md="6" xs="12">
            <LogoColumn></LogoColumn>
          </Col>

          <Col md="6" xs="12">
            <FormWrapper>
              {completed && (
                <Styled.ConfirmationWrap>
                  <h2>{l10n.resetPassword.title}</h2>
                  <Text>
                    {l10n.resetPassword.completed} <br />
                    {l10n.resetPassword.proceedTo}{" "}
                    <GlobalLink to={"/login"}>
                      {l10n.resetPassword.login}
                    </GlobalLink>
                  </Text>
                </Styled.ConfirmationWrap>
              )}
              {!completed && (
                <React.Fragment>
                  <Styled.Form onSubmit={resetPassword}>
                    <h2>{l10n.resetPassword.title}</h2>
                    <FormGroup>
                      <Label for="password">
                        <i className="fas fa-lock"></i>
                        {l10n.resetPassword.password + "*"}
                      </Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder={l10n.resetPassword.password + "*"}
                        invalid={!!validate.password}
                        value={password.password}
                        onChange={handleChange}
                      />
                      <FormText color="white">
                        {l10n.resetPassword.passwordDescription}
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmPassword">
                        <i className="fas fa-lock"></i>
                        {l10n.resetPassword.confirmPassword + "*"}
                      </Label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder={l10n.resetPassword.confirmPassword + "*"}
                        invalid={!!validate.confirmPassword}
                        value={password.confirmPassword}
                        onChange={handleChange}
                      />
                      <FormText color="white">
                        {l10n.resetPassword.confirmPasswordDescription}
                      </FormText>
                    </FormGroup>
                    <FormGroup align="right">
                      <GlobalButton
                        color="primary"
                        disabled={props.user.resetPassword.loading}
                      >
                        {l10n.resetPassword.savePassword}
                      </GlobalButton>
                    </FormGroup>
                    {props.user.resetPassword.error && (
                      <Alert color="warning">
                        {props.user.resetPassword.error.map((err, index) => (
                          <span key={index}>{l10n.serverErrors[err]}</span>
                        ))}
                      </Alert>
                    )}
                  </Styled.Form>
                </React.Fragment>
              )}
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </LoginWrapper>
  );
}

export default connect(({ user }) => ({ user }), {
  resetPassword,
  resetPasswordReset,
})(ResetPassword);
