import styled from "styled-components";
import { PDFIcon } from "../../pages/Requests/requests.styles";

export default {
  Widget: styled.div`
    background: ${(props) => props.theme.colors[0]};
    box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
    border-radius: 4px;
    padding: 13px 10px;
    min-height: 150px;
    margin: 0 0 20px;
    height: ${(props) => (props.size === "small" ? "auto" : "350px")};
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.1s ease-in-out;

    &:hover {
      box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }

    @media (min-width: 1200px) and (max-width: 1550px) {
      height: ${(props) => (props.size === "small" ? "250px" : "414px")};
    }

    @media (min-width: 1200px) and (max-width: 1370px) {
      height: ${(props) => (props.size === "small" ? "250px" : "475px")};
    }

    @media (max-width: 1199px) {
      height: ${(props) => (props.size === "small" ? "auto" : "auto")};
    }

    @media (max-width: 767px) {
      align-items: center;
      flex-direction: row;
      min-height: 10px;
      height: auto;
    }
  `,

  Content: styled.div`
    flex-direction: column;
  `,

  Title: styled.p`
    font-size: 15px;
    margin: 0;
    padding: 0;
  `,

  WidgetDescription: styled.div`
    color: #6a707e;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin-top: 10px;

    p {
      line-height: auto;
      margin: 0;
      padding: 0;
    }
  `,

  Spacer: styled.div`
    flex-grow: 1;
  `,

  Footer: styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  `,

  Icon: styled(PDFIcon).attrs({})``,
};
