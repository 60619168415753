export const Types = {
  CHANGE_PASSWORD: "user/change_password",
  CHANGE_PASSWORD_SUCCESS: "user/change_password_success",
  CHANGE_PASSWORD_ERROR: "user/change_password_error",
  CHANGE_PASSWORD_RESET: "user/change_password_reset"
};

export const changePassword = postData => ({
  type: Types.CHANGE_PASSWORD,
  payload: postData
});

export const changePasswordSuccess = () => ({
  type: Types.CHANGE_PASSWORD_SUCCESS
});

export const changePasswordError = error => ({
  type: Types.CHANGE_PASSWORD_ERROR,
  payload: error
});

export const changePasswordReset = () => ({
  type: Types.CHANGE_PASSWORD_RESET
});
