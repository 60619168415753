import * as Joi from "joi-browser";

const schema = Joi.object().keys({
  oldPassword: Joi.string().required(),
  newPassword: Joi.string().required(),
  confirmPassword: Joi.string()
    .valid(Joi.ref("newPassword"))
    .required()
});

export default formData =>
  Joi.validate(formData, schema, { abortEarly: false }, err => {
    if (!err) return null;
    let validationData = {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null
    };

    err.details.map(error => (validationData[error.path[0]] = error.message));

    return validationData;
  });
