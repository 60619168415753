import axios from "axios/index";

export const getNotifications = userId => {
  return axios.get(`/user/${userId}/notification`, {
    params: { IsMarkedAsRead: 0, IsCleared: 0 }
  });
};

export const markReadNotification = notificationId => {
  return axios.post(`/notification/${notificationId}/mark-as-read`);
};

export const markAllReadNotifications = userId => {
  return axios.post(`/user/${userId}/notification/mark-as-read`);
};
