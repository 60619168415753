import styled, { keyframes } from "styled-components";

export const ContentWrap = styled.div`
  display: flex;
  height: 100%;
`;

export const ContentInner = styled.div`
  margin: auto;

  a {
    font-size: 24px;
  }
`;

export const rotate = keyframes`
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
`;

export const Icon = styled.i`
  font-size: 160px;
  margin: 0 0 25px 0;
  color: ${props => props.theme.colors[2]};
  animation: ${rotate} 2s linear infinite;
  &[name="loading"] {
    color: ${props => props.theme.colors[12]};
  }
  &[name="error"] {
    color: ${props => props.theme.colors[8]};
  }
`;
