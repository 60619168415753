import styled from "styled-components";

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 60px;
  > img {
    max-width: 100%;
  }

  @media (max-width: 991px) and (min-width: 576px) {
    margin: 0 0 60px;
  }

  @media (max-width: 575px) {
    margin: 0 0 30px;
  }
`;

export const Info = styled.div`
  background-color: ${(props) => props.theme.colors[0]};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 28px;
  text-align: center;

  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[37]};
    margin: 0;
  }

  > a {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.colors[19]};
  }

  @media (min-width: 1200px) {
    min-height: 600px;
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    min-height: 500px;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    min-height: 400px;
  }

  @media (max-width: 575px) {
    padding: 20px;

    > h2 {
      font-size: 20px;
      line-height: 30px;
    }

    > a {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const Footer = styled.div`
  margin: 0 50px;
`;

export const FooterText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #999999;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
`;

export const Stores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StoreItem = styled.div`
  height: 40px;
  width: 100%;

  a {
    img {
      object-fit: contain;
      height: 40px;
      width: auto;
    }
  }
`;
