import styled from "styled-components";

export const Logo = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors[0]};
  box-shadow: 0 2px 5px 0 ${(props) => props.theme.colors[13]};
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  width: 100%;

  img {
    // height: 40px;
    margin-right: 10px;
    object-fit: contain;
    width: 80%;
  }

  @media (max-width: 1024px) {
    box-shadow: none;
    height: auto;
  }
`;

export const Title = styled.p`
  color: #192f7c;
  font-family: "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;
`;
