import { Types } from "../actions/payAll.actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: null,
  data: null
};

export default function payAll(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PAYALL_SIGNATURE: {
      return {
        ...state,
        ...INITIAL_STATE,
        loading: true
      };
    }

    case Types.GET_PAYALL_SIGNATURE_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        data: action.payload
      };
    }

    case Types.GET_PAYALL_SIGNATURE_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    }

    case Types.GET_PAYALL_SIGNATURE_RESET: {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
}
