import React, { useState } from "react";
import { Button } from "reactstrap";
import l10n from "../../../../../../localization";
import MobileMeterRow from "../MobileMeterRow/MobileMeterRow";
import * as Styled from "./mobileMeterRows.styles";

const MobileMeterRows = ({ meterSiteCode, meterCode, meters }) => {
  const [expanded, setExpanded] = useState(false);
  const filteredBySiteCode = meters.meterReadingsByDay.result.filter(
    meterReadingByDay => meterReadingByDay.meterSiteCode === meterSiteCode
  );

  let readings = [];

  if (filteredBySiteCode.length > 0) {
    const showingMeter = filteredBySiteCode[0].meters.filter(
      fMeterSideCode => fMeterSideCode.meterCode === meterCode
    );

    if (showingMeter.length > 0) {
      if (showingMeter[0].readings && showingMeter[0].readings.length > 0) {
        readings = [...showingMeter[0].readings];
      }
    }
  }

  if (readings.length <= 0) return null;
  return (
    <div>
      <Styled.ActionHolder>
        <Button color="link" onClick={() => setExpanded(!expanded)}>
          <Styled.MoreInfoWrapper>
            <p>{l10n.reports.showReports}</p>
            <i className={`far fa-chevron-${expanded ? "up" : "down"}`}></i>
          </Styled.MoreInfoWrapper>
        </Button>
      </Styled.ActionHolder>
      {expanded &&
        readings.map((meterReading, index) => (
          <MobileMeterRow
            key={`${meterReading}_${index}`}
            reading={meterReading}
          />
        ))}
    </div>
  );
};

export default MobileMeterRows;
