import React, { useState, useEffect } from "react";
import OptionBox from "../../components/OptionBox/OptionBox";
import { connect } from "react-redux";
import {
  getMeters,
  meterReadingsByDay,
} from "../../redux/actions/meter.actions";
import { getInvoices } from "../../redux/actions/invoice.actions";
import Loader from "../../components/Loader";
import l10n from "../../localization";
import ChangeLayoutButton from "./_components/ChangeLayoutButton";
import GraphList from "./_components/GraphList";
import TableList from "./_components/TableList";
import moment from "moment";
import * as Styled from "./reports.styles";
import DatePicker from "react-datepicker";
import theme from "../../theme/default";

const Reports = (props) => {
  const [currentDisplay, setCurrentDisplay] = useState("graph");
  const [startDate, setStartDate] = useState(
    moment().startOf("year").subtract(2, "years").toDate()
  );
  const [endDate, setEndDate] = useState(null);
  const [meterSiteCode, setMeterSiteCode] = useState("all");
  const [meterCode, setMeterCode] = useState("all");

  useEffect(() => {
    if (!props.meters.loaded) props.getMeters();
    props.getInvoices(
      false,
      meterSiteCode !== "all" ? meterSiteCode : null,
      moment(startDate).format("YYYY-MM-DD"),
      endDate ? moment(endDate).format("YYYY-MM-DD") : null
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.meters.loaded) {
      props.meterReadingsByDay({
        meterSiteCode: meterSiteCode,
        meterCode: meterCode,
        dateFrom: moment(startDate).format("YYYY-MM-DD"),
        dateTo: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      });
    }

    props.getInvoices(
      false,
      meterSiteCode !== "all" ? meterSiteCode : null,
      moment(startDate).format("YYYY-MM-DD"),
      endDate ? moment(endDate).format("YYYY-MM-DD") : null
    );

    //eslint-disable-next-line
  }, [meterSiteCode, meterCode, startDate, endDate, props.meters.loaded]);

  const changeDisplay = (newDisplay) => {
    setCurrentDisplay(newDisplay);
  };

  if (props.meters.loading || props.invoices.loading) return <Loader />;

  const generateMeters = () => {
    const filteredMeters = props.meters.result.filter(
      (meter) => meter.meterSiteCode === meterSiteCode
    );
    if (filteredMeters.length > 0) {
      const targetMeter = filteredMeters[0];
      return targetMeter.userMeters.map((userMeter) => {
        return {
          slug: userMeter.meterCode,
          value: userMeter.meterCode,
        };
      });
    }

    return [];
  };

  const generateReports = () => {
    const readings = props.meters.meterReadingsByDay.result;
    let newReadings = [];

    if (readings) {
      newReadings = readings.map((reading) => {
        const filteredMeters = props.meters.result.filter(
          (meter) => meter.meterSiteCode === reading.meterSiteCode
        );

        const meterSiteCode =
          filteredMeters.length > 0 ? filteredMeters[0].meterSiteCode : null;

        return {
          ...(filteredMeters.length > 0 ? filteredMeters[0] : null),

          ...reading,
          invoices: meterSiteCode
            ? props.invoices.result.filter(
                (invoice) =>
                  invoice.meters.filter(
                    (invoiceMeter) =>
                      invoiceMeter.meterSiteCode === meterSiteCode
                  ).length > 0
              )
            : [],
        };
      });
    }

    return newReadings;
  };

  return (
    <div>
      <Styled.Filters>
        <Styled.FilterOptions>
          <Styled.FilterItem>
            <span>{l10n.reports.site_address}</span>
            <OptionBox
              options={[
                {
                  slug: "all",
                  value: l10n.reports.allMeasurementPoints,
                },
                ...props.meters.result.map((meter) => {
                  return {
                    slug: meter.meterSiteCode,
                    value: `${meter.siteAddress}`,
                  };
                }),
              ]}
              selected={meterSiteCode}
              onSelect={(option) => {
                setMeterSiteCode(option.slug);
                setMeterCode("all");
              }}
            />
          </Styled.FilterItem>
          <Styled.FilterItem>
            <span>{l10n.reports.meter}</span>
            <OptionBox
              options={[
                {
                  slug: "all",
                  value: l10n.reports.allMeters,
                },
                ...generateMeters(),
              ]}
              selected={meterCode}
              onSelect={(option) => setMeterCode(option.slug)}
            />
          </Styled.FilterItem>
          <Styled.RangeFilter>
            <Styled.FilterItem>
              <span>{l10n.reports.period_from} </span>
              <DatePicker
                selected={startDate}
                placeholderText={l10n.send.choose}
                dateFormat="dd.MM.yyyy"
                locale={l10n.general.locale}
                onChange={(date) => setStartDate(new Date(date))}
              />
            </Styled.FilterItem>
            <Styled.FilterItem>
              <span>{l10n.reports.perod_to} </span>
              <DatePicker
                selected={endDate}
                placeholderText={l10n.send.choose}
                dateFormat="dd.MM.yyyy"
                locale={l10n.general.locale}
                onChange={(date) => setEndDate(new Date(date))}
              />
            </Styled.FilterItem>
          </Styled.RangeFilter>
        </Styled.FilterOptions>
        <Styled.FilterSwitch>
          <ChangeLayoutButton
            display="graph"
            label={l10n.reports.chart}
            onChangeDisplay={changeDisplay}
            currentDisplay={currentDisplay}
          />
          <Styled.FilterSpacer />
          <ChangeLayoutButton
            display="table"
            label={l10n.reports.table}
            onChangeDisplay={changeDisplay}
            currentDisplay={currentDisplay}
          />
        </Styled.FilterSwitch>
      </Styled.Filters>
      {currentDisplay === "graph" && (
        <Styled.Legend>
          <Styled.LegendItem>
            <Styled.LegendName>{l10n.reports.readingType}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[42]} />
            <Styled.LegendName>{l10n.reports.readingType1}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[43]} />
            <Styled.LegendName>{l10n.reports.readingType2}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[44]} />
            <Styled.LegendName>{l10n.reports.readingType3}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[45]} />
            <Styled.LegendName>{l10n.reports.readingType4}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[46]} />
            <Styled.LegendName>{l10n.reports.readingType5}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[47]} />
            <Styled.LegendName>{l10n.reports.readingType6}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[48]} />
            <Styled.LegendName>{l10n.reports.readingType7}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[49]} />
            <Styled.LegendName>{l10n.reports.readingType8}</Styled.LegendName>
          </Styled.LegendItem>
          <Styled.LegendItem>
            <Styled.LegendIndicator color={theme.colors[50]} />
            <Styled.LegendName>{l10n.reports.readingType9}</Styled.LegendName>
          </Styled.LegendItem>
        </Styled.Legend>
      )}
      <>
        {props.meters.meterReadingsByDay.loading ? (
          <Loader />
        ) : (
          <>
            {currentDisplay === "graph" && (
              <GraphList
                data={generateReports()}
                startDate={startDate}
                endDate={endDate}
              />
            )}

            {currentDisplay === "table" && (
              <TableList
                data={generateReports()}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};

export default connect(({ meters, invoices }) => ({ meters, invoices }), {
  getMeters,
  getInvoices,
  meterReadingsByDay,
})(Reports);
