import React, { useEffect } from "react";
import * as Styled from "./requests.styles";
import { connect } from "react-redux";
import {
  getUserDocuments,
  saveUserDocuments,
} from "../../redux/actions/requests.actions";
import { getMeters } from "../../redux/actions/meter.actions";
import { getDocumentTypes } from "../../redux/actions/document.actions";
import { Col, Row, Table } from "reactstrap";
import Loader from "../../components/Loader";
import l10n from "../../localization";
import RequestWidget from "../../components/RequestWidget";
import moment from "moment";
import { useHistory } from "react-router-dom";

const Requests = (props) => {
  const history = useHistory();

  useEffect(() => {
    props.getMeters();
    props.getUserDocuments();
    props.getDocumentTypes();
    // eslint-disable-next-line
  }, []);

  // Renders

  const renderDocumentTypes = () => {
    if (!props.documents.results || props.documents.results.length === 0)
      return null;

    return props.documents.results.map((item, index) => (
      <Col xs={12} xl={6} key={index}>
        <RequestWidget
          item={item}
          onClick={(v) => history.push(`/requestform/${item.documentId}`)}
        />
      </Col>
    ));
  };

  const renderuserDocumentsSection = () => {
    if (props.requests) {
      if (props.requests.loading) return <Loader inline />;

      if (
        props.requests.loaded &&
        props.requests.data &&
        props.requests.data.length > 0
      ) {
        return (
          <Row>
            <Col md={12}>
              <Styled.Section>
                <Styled.Title2>{l10n.requests.tableTitle}</Styled.Title2>
                <Styled.Card>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{l10n.requests.date}</th>
                        <th>{l10n.requests.type}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.requests.data.map((item) => (
                        <tr key={item.dateOfRequest}>
                          <td>
                            {moment(item.dateOfRequest).format(
                              "D.M.YYYY HH:mm"
                            )}
                          </td>
                          <td>{item.typeOfRequest}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Styled.Card>
              </Styled.Section>
            </Col>
          </Row>
        );
      }
    }

    return null;
  };

  if (props.requests.saving || props.documents.loading || props.meters.loading)
    return <Loader />;

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Styled.Header>
            <Styled.HeaderLeft>
              <Styled.Title>{l10n.requests.title}</Styled.Title>
              <Styled.SubtitleView>
                <Styled.Subtitle>{l10n.requests.subtitle1}</Styled.Subtitle>
                <Styled.IconWrapper>
                  <Styled.PDFIcon />
                </Styled.IconWrapper>
                <Styled.Subtitle>{l10n.requests.subtitle2}</Styled.Subtitle>
              </Styled.SubtitleView>
            </Styled.HeaderLeft>
          </Styled.Header>
          <Styled.Content>
            <Row>{renderDocumentTypes()}</Row>
          </Styled.Content>
        </Col>
      </Row>
      {renderuserDocumentsSection()}
    </React.Fragment>
  );
};

export default connect(
  ({ requests, documents, meters }) => ({ requests, documents, meters }),
  {
    getUserDocuments,
    saveUserDocuments,
    getDocumentTypes,
    getMeters,
  }
)(Requests);
