import React, { useState, useEffect } from "react";
import { ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import PropTypes from "prop-types";
import l10n from "../../localization";
import Styled from "./Modal.styles";

function ModalMessage(props) {
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  const closeModal = () => {
    setModalOpen(false);
    props.onClose();
  };

  return (
    <div>
      <Styled.ModalHolder isOpen={modalOpen}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter hidden={!props.footer}>
          <Button color="secondary" onClick={closeModal}>
            {props.closeButtonText || l10n.modal.close}
          </Button>
          {props.onAccept && (
            <Button color="primary" onClick={props.onAccept}>
              {props.submitButtonText || l10n.modal.yes}
            </Button>
          )}
        </ModalFooter>
      </Styled.ModalHolder>
    </div>
  );
}

ModalMessage.defaultProps = {
  onClose: () => {}
};

ModalMessage.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default ModalMessage;
