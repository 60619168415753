import styled from "styled-components";

export const Wrapper = styled.section`
  padding-left: 256px;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
  @media print {
    padding-left: 0;
  }
`;

export const Content = styled.section`
  background-color: ${props => props.theme.colors[24]};
  height: 100%;
  min-height: 100vh;
  padding: 120px 30px 30px 30px;

  @media (max-width: 1024px) {
    padding: 75px 15px 15px 15px;
  }
`;
