import React from "react";
import PropTypes from "prop-types";
import Styled from "./DashboardWidget.styled";
import Helpers from "../../helpers/helpers";

const DashboardWidget = ({
  title,
  description,
  value,
  valueEur,
  negative,
  formatPrice
}) => {
  return (
    <Styled.Widget>
      <h2>{title}</h2>
      <Styled.WidgetNumber negative={value < 0 || negative === true}>
        {formatPrice ? Helpers.formatPrice(value) : value}{" "}({formatPrice ? Helpers.formatPriceHrk(valueEur) : value})
      </Styled.WidgetNumber>
      {description && (
        <Styled.WidgetDescription>{description}</Styled.WidgetDescription>
      )}
    </Styled.Widget>
  );
};

DashboardWidget.propTypes = {
  negative: PropTypes.bool,
  formatPrice: PropTypes.bool
};

DashboardWidget.defaultProps = {
  negative: null,
  formatPrice: false
};

export default DashboardWidget;
