import styled from "styled-components";
import { Card as RCard } from "reactstrap";

export const Card = styled(RCard)`
  margin: 0 0 25px 0;
  padding: 25px;
`;

export const Colors = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    display: inline-block;
  }
`;

export const ColorBlock = styled.div`
  border: 1px solid ${props => props.theme.colors[10]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  background-color: ${props => props.theme.colors[props.color]};
  margin: 0 5px 5px 0;
`;
