export const Types = {
  SEND_ENQUIRY: "user/send_enquiry",
  SEND_ENQUIRY_SUCCESS: "user/send_enquiry_success",
  SEND_ENQUIRY_ERROR: "user/send_enquiry_error",
  SEND_ENQUIRY_RESET: "user/send_enquiry_reset"
};

export const sendEnquiry = message => ({
  type: Types.SEND_ENQUIRY,
  payload: { message: message }
});

export const sendEnquirySuccess = () => ({
  type: Types.SEND_ENQUIRY_SUCCESS
});

export const sendEnquiryError = () => ({
  type: Types.SEND_ENQUIRY_ERROR
});

export const sendEnquiryReset = () => ({
  type: Types.SEND_ENQUIRY_RESET
});
