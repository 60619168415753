import React from "react";
import Styled from "./ChangeLayoutButton.styles";

export default ({ display, onChangeDisplay, currentDisplay, label }) => {
  return (
    <Styled.changeLayout
      onClick={() => onChangeDisplay(display)}
      active={currentDisplay === display}
    >
      {label}
    </Styled.changeLayout>
  );
};
