import styled from "styled-components";
import { Link as RLink } from "react-router-dom";

export const Link = styled(RLink)`
  color: ${(props) => props.theme.colors[8]};
  &:hover {
    color: ${(props) => props.theme.colors[8]};
  }
`;

export const minimumNewPosition = styled.span`
  color: ${(props) => props.theme.colors[8]};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[23]};
`;

export const HeaderText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[27]};
`;

export const HeaderLink = styled.a``;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const OptionText = styled.span`
  color: ${(props) => props.theme.colors[27]};
`;

export const InputWrap = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    display: inline-block;
  }
`;

export const Text = styled.div`
  margin: 10px 0;
`;

export const MeterDataView = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  display: flex;
  flex-direction: column;
  padding: 10px 10px 15px;
`;

export const MeterDataRow = styled.div`
  display: flex;
`;

export const MeterDataKey = styled.p`
  margin: 0 10px 0 0;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.colors[27]};
`;

export const MeterDataValue = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.colors[27]};
`;

export const MeterReadingView = styled.div`
  padding: 10px 10px 15px;
`;

export const MeterReadingRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 5px;
`;

export const MeterReadingKey = styled.p`
  margin: 0 10px 0 0;
  padding: 0;
  min-width: 220px;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.theme.colors[27]};
`;

export const MeterReadingValue = styled.div`
  input {
    font-size: 13px;
    line-height: 19px;
    border: none;
    background-color: transparent;
    border-radius: 0;
    height: 35px;
    border-bottom: 1px solid ${(props) => props.theme.colors[31]};
    padding: 0;
    max-width: 100%;
    min-width: 300px;
  }

  input:disabled {
    background: white;
    cursor: not-allowed;
  }

  @media (max-width: 1024px) {
    input {
      min-width: 0;
    }
  }
`;

export const SubmitButtonView = styled.div`
  margin-top: 25px;

  button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }
  }
`;

export const Card = styled.div`
  margin: 0 0 25px 0;
  background-color: ${(props) => props.theme.colors[0]};
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
`;

export const LastPositionError = styled.span`
  display: inline;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const ParentForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ParentFormHeader = styled.div`
  align-items: baseline;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.01em;
  line-height: 22px;

  color: ${(props) => props.theme.colors[27]};
  display: flex;
  flex-direction: row;
  padding: 20px;
  position: relative;

  p {
    margin-right: 15px;
  }

  input {
    border: none;
    border-bottom: 1px solid #ddd;
  }

  i {
    margin-left: -10px;
    pointer-events: none;
    z-index: 1;
  }
`;

export const ParentFormTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  width: 100%;
`;

export const TableRow = styled.div`
  border-bottom: 1px solid #efefef;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  width: 100%;
`;

export const TableCol = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => `${props.theme.colors[18]}77`};
  margin: 0 10px;

  input {
    color: ${(props) => props.theme.colors[23]};
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;

    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;

    &::placeholder {
      color: ${(props) => `${props.theme.colors[23]}77`};
    }
  }

  &:nth-child(1) {
    min-width: 75px;
    width: 75px;
  }

  &:nth-child(2) {
    min-width: 250px;
    width: 250px;
  }

  &:nth-child(3) {
    min-width: 200px;
    text-align: right;
    width: 200px;

    input {
      text-align: right;
    }
  }

  &:nth-child(4) {
    min-width: 250px;
    width: 100%;
  }
`;

export const TableFooterCol = styled.div`
  min-width: 365px;
  text-align: right;
  width: 365px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.01em;
  color: ${(props) => `${props.theme.colors[18]}77`};
`;

export const TableFooterInput = styled.div`
  margin: 0 10px;
  min-width: 200px;
  text-align: right;
  width: 200px;

  input {
    color: ${(props) => props.theme.colors[23]};
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-align: right;

    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;

    &::placeholder {
      color: ${(props) => `${props.theme.colors[23]}77`};
    }
  }

  input::after {
    content: " ";
    display: inline-block;
    border-bottom: 1px solid #f00;
    border-right: 1px solid #f00;
    height: 10px;
    width: 10px;
    transform: rotate(-45deg);
  }

  input:disabled {
    background-color: white;
  }
`;

export const LegendItem = styled.div`
  align-items: center;
  display: flex;
`;

export const LegendIndicator = styled.div`
  background-color: ${(props) => props.color || "green"};
  border-radius: 3px;
  height: 6px;
  margin-right: 3px;
  width: 6px;
`;

export const LegendName = styled.p`
  color: ${(props) => props.theme.colors[27]};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;
`;
