import { Types } from "../actions/requests.actions";

const initialState = {
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  data: [],
  error: null,
  saveError: null,
  documentSaving: false,
  documentSaved: false,
  documentError: false,
};

export const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_USER_DOCUMENTS:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        data: [],
        documentSaving: false,
        documentSaved: false,
        documentError: false,
      };

    case Types.GET_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
        documentSaving: false,
        documentSaved: false,
        documentError: false,
      };

    case Types.GET_USER_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        data: [],
        documentSaving: false,
        documentSaved: false,
        documentError: false,
      };

    case Types.SAVE_USER_DOCUMENTS:
      return {
        ...state,
        // saving: true,
        // saved: false,
        // saveError: null,
        documentSaving: true,
        documentSaved: false,
        documentError: false,
      };

    case Types.SAVE_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        saveError: null,
        documentSaving: false,
        documentSaved: true,
        documentError: false,
      };

    case Types.SAVE_USER_DOCUMENTS_ERROR:
      return {
        ...state,
        saving: false,
        saved: false,
        saveError: action.payload,
        documentSaving: false,
        documentSaved: false,
        documentError: true,
      };

    default:
      return state;
  }
};

export default requestsReducer;
