import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/string/repeat";
import "unorm/lib/unorm";
import React from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

import i10n from "./localization";
import App from "./App";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import reducers from "./redux/reducers";
import rootSaga from "./redux/sagas";
import "react-datepicker/dist/react-datepicker.css";
import hr from "date-fns/locale/hr";
import { registerLocale } from "react-datepicker";
registerLocale("hr-HR", hr);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;

i10n.setLanguage(process.env.REACT_APP_LANG);
require("moment/locale/" + i10n.general.momentLocale);

const sagaMiddleware = createSagaMiddleware();
let middlewares = [];

if (process.env.NODE_ENV !== "production") {
  const logger = require("redux-logger").createLogger();
  middlewares = [...middlewares, sagaMiddleware, logger];
} else {
  middlewares = [...middlewares, sagaMiddleware];
}
const store = createStore(reducers, applyMiddleware(...middlewares));
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
