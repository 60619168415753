import React, { useEffect, useState, useRef } from "react";
import * as Styled from "./OptionBox.styles";
import Loader from "../../components/Loader";
import theme from "../../theme/default";

export default function OptionBox(props) {
  const [opened, setOpened] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpened(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const toggleStatus = () => {
    setOpened(!opened);
  };

  const matches = props.options.filter(
    (option) => option.slug === props.selected
  );

  return (
    <Styled.Wrapper wrapperWidth={props.wrapperWidth} ref={wrapperRef}>
      <Styled.Dropdown onClick={toggleStatus} opened={opened}>
        {props.loading && (
          <Loader inline={true} color={theme.colors[1]} size={20} />
        )}
        {!props.loading && props.options && (
          <span>
            {matches.length > 0
              ? props.showSlug === true? matches[0].slug : matches[0].value
              : props.placeholder || "Select"}{" "}
            <i className="fas fa-caret-down" />
          </span>
        )}
      </Styled.Dropdown>
      {!props.loading && (
        <Styled.Options opened={opened} minWidth={props.minWidth}>
          {(props.options || []).map((option, index) => (
            <li key={index}>
              <span
                onClick={() => {
                  toggleStatus();
                  props.onSelect(option);
                }}
              >
                {option.value}
              </span>
            </li>
          ))}
        </Styled.Options>
      )}
    </Styled.Wrapper>
  );
}
