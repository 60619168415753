import styled from "styled-components";
import { PDFGreenIcon } from "../../components/Icons/Icons";

export const Header = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 30px;
`;

export const HeaderLeft = styled.div`
  flex-grow: 1;
`;

export const HeaderRight = styled.div`
  button {
    padding: 10px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: ${(props) => props.theme.colors[0]};
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }
  }
`;

export const Title2 = styled.p`
  color: #323c47;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
`;

export const SubtitleView = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const Subtitle = styled.p`
  color: #323c47;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 18px;
  margin: 0;
  padding: 0;
`;

export const IconWrapper = styled.div`
  padding: 0 5px;
`;

export const PDFIcon = styled(PDFGreenIcon).attrs({})`
  margin-left: 15px;
`;

export const Content = styled.div`
  display: flex;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[23]};
  margin: 0 0 25px;
`;

export const Description = styled.span`
  color: ${(props) => props.theme.colors[27]};
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0 0 20px;
  padding: 0;
`;

export const DescriptionText = styled.p`
  margin: 0;
  margin-right: 5px;
  padding: 0;
`;

export const Card = styled.div`
  margin: 0 0 20px 0;
  background-color: ${(props) => props.theme.colors[0]};
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};

  table {
    th {
      border-top: none;
      border-bottom: 1px solid ${(props) => props.theme.colors[17]};
      padding: 20px;
      padding-left: 0;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[18]};
      vertical-align: middle;

      &:first-of-type {
        padding-left: 24px;
      }

      &:last-of-type {
        padding-left: 0;
      }
    }

    td {
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[22]};
      padding-left: 0;

      &:first-of-type {
        padding-left: 22px;
      }
    }

    thead {
    }

    tbody {
      tr:not(:last-of-type) {
        td {
          border-bottom: 1px solid ${(props) => props.theme.colors[17]};
        }
      }
    }
  }
`;
