export const Types = {
  RESET_PASSWORD: "user/reset_password",
  RESET_PASSWORD_SUCCESS: "user/reset_password_success",
  RESET_PASSWORD_ERROR: "user/reset_password_error",
  RESET_PASSWORD_RESET: "user/reset_password_reset"
};

export const resetPassword = payload => ({
  type: Types.RESET_PASSWORD,
  payload: payload
});
export const resetPasswordSuccess = () => ({
  type: Types.RESET_PASSWORD_SUCCESS
});
export const resetPasswordError = error => ({
  type: Types.RESET_PASSWORD_ERROR,
  payload: error
});
export const resetPasswordReset = () => ({
  type: Types.RESET_PASSWORD_RESET
});
