import styled from "styled-components";

export const NotificationWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9998;
  padding-top: 61px;
`;

export const NotificationDropdown = styled.div`
  min-width: 600px;
  width: 100%;
  background: ${(props) => props.theme.colors[0]};
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.colors[13]};
  display: ${(props) => (props.visible ? "block" : "none")};
  max-height: calc(100vh - 80px);
  border-radius: 3px;
`;

export const NotificationsHolder = styled.div`
  min-height: 300px;
  max-height: 60vh;
  overflow-y: auto;
  padding: 8px 16px;
`;

export const ArrowTop = styled.div`
  position: absolute;
  top: 32px;
  right: 0;
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 9999;
`;

export const Notification = styled.div`
  padding: 22px;
  border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors[18]};
`;

export const ClearWrap = styled.div`
  padding: 12px 18px;
  font-size: 0;
  line-height: 0;
  border-top: 1px solid ${(props) => props.theme.colors[17]};
`;

export const ClearAll = styled.button`
  padding: 4px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors[0]};
  background-color: ${(props) => props.theme.colors[21]};
  transition: 0.2s background-color ease-in;

  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors[29]} !important;
  }
`;
