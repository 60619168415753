import styled from "styled-components";

export const Filters = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const FilterOptions = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const FilterItem = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0;

  span,
  p,
  a {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
  }

  > span {
    color: ${(props) => props.theme.colors[27]};
    margin-right: 5px;
  }

  > div > div {
    padding: 0 20px 0 3px;
  }

  .react-datepicker__input-container {
    padding: 0;
  }

  .react-datepicker-wrapper {
    padding: 0;
    margin: 0;

    input {
      background-color: transparent;
      color: ${(props) => props.theme.colors[43]};
      cursor: pointer;
      border: none;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 3px;
      text-align: center;
      width: 90px;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const FilterSwitch = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterSpacer = styled.div`
  width: 10px;
`;

export const RangeFilter = styled.div`
  display: flex;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const LegendItem = styled.div`
  align-items: center;
  display: flex;
  margin-right: 10px;
`;

export const LegendIndicator = styled.div`
  background-color: ${(props) => props.color || "green"};
  border-radius: 3px;
  height: 6px;
  margin-right: 3px;
  width: 6px;
`;

export const LegendName = styled.p`
  color: ${(props) => props.theme.colors[27]};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;
`;
