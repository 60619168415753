import { all } from "redux-saga/effects";
import user from "./user";
import meter from "./meter.sagas";
import invoice from "./invoice.sagas";
import notification from "./notification.sagas";
import TwoDCode from "./TwoDCode.sagas";
import eInvoice from "./eInvoice.sagas";
import payAll from "./payAll.sagas";
import requests from "./request.sagas";
import documents from "./document.sagas";

export default function* rootSaga() {
  yield all([
    ...user,
    ...meter,
    ...invoice,
    ...notification,
    ...TwoDCode,
    ...eInvoice,
    ...payAll,
    ...requests,
    ...documents,
  ]);
}
