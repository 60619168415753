import styled from "styled-components";

export const Card = styled.div`
  margin: 0 0 20px 0;
  background-color: ${(props) => props.theme.colors[0]};
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};

  label {
    color: ${(props) => props.theme.colors[27]};
    font-size: 15px;
    letter-spacing: 0.01em;
    line-height: 22px;
    margin: 0;
  }

  input {
    background-color: transparent;
    color: ${(props) => props.theme.colors[23]};
    border: none;
    border-radius: 0;
    outline: none;

    &:not(:disabled) {
      border-bottom: 1px solid ${(props) => props.theme.colors[17]};
    }

    &:focus {
      background-color: transparent;
      box-shadow: none;
    }

    &:disabled {
      background-color: transparent;
    }
  }

  button {
    padding: 4px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors[0]};
    background-color: ${(props) => props.theme.colors[21]};
    transition: 0.2s background-color ease-in;

    &:hover,
    &:active,
    &:focus {
      background-color: ${(props) => props.theme.colors[29]} !important;
    }
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors[17]};
  display: flex;
  padding: 20px 24px;
`;

export const HeaderTitle = styled.p`
  color: ${(props) => props.theme.colors[28]};
  flex-grow: 1;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 19px;
  margin: 0;
  padding: 0;
`;

export const Body = styled.div`
  padding: 20px 24px;
`;

export const PersonalDataKeyCol = styled.div`
  align-self: center;
  margin-left: 15px;
  width: 75px;
`;

export const PersonalDataValueCol = styled.div`
  align-self: center;
  flex-grow: 1;
  margin-right: 15px;
`;

export const ContactDataKeyCol = styled.div`
  align-self: center;
  margin-left: 15px;
  width: 140px;
`;

export const ContactDataValueCol = styled.div`
  align-self: center;
  flex-grow: 1;
  margin-right: 15px;
`;
