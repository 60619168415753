import React from "react";
import * as Styled from "./InvoiceListMobile.styles";
import PropTypes from "prop-types";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import l10n from "../../../../localization";
import Helpers from "../../../../helpers/helpers";
import MobileInvoiceEntry from "./_components/MobileInvoiceEntry/MobileInvoiceEntry";
import PayPopup from "../../../PayPopup/PayPopup";

function InvoiceListMobile(props) {
  return (
    <React.Fragment>
      <Styled.Card>
        <CardHeader>
          <Row>
            <Col md={8}>{props.title}</Col>
            {props.openSum > 0 && (
              <Col align="right">
                <Styled.OverDue>
                  {l10n.invoices.openInvoices}:{" "}
                  <span>{Helpers.formatPrice(props.openSum)}</span>
                </Styled.OverDue>
              </Col>
            )}
          </Row>
        </CardHeader>
        {props.invoices.length === 0 && (
          <CardBody>
            <Styled.noOpenInvoices>
              {props.openInvoices
                ? l10n.invoices.noOpenInvoices
                : l10n.invoices.noInvoices}
              <i className="far fa-check" />
            </Styled.noOpenInvoices>
          </CardBody>
        )}
      </Styled.Card>

      {props.openSum > 0 && (
        <Styled.Card>
          <Row>
            <Col align="right">
              <PayPopup
                invoices={props.invoices.filter(
                  (invoice) => invoice.isPayed === "Open"
                )}
                buttonLabel={l10n.invoices.pay}
                payAllInvoices={true}
              />
            </Col>
          </Row>
        </Styled.Card>
      )}

      {(props.invoices || []).map((invoice) => (
        <MobileInvoiceEntry
          key={invoice.invoiceId}
          invoice={invoice}
          gatewayWSPAYForm={props.gatewayWSPAYForm}
          onOpenInvoice={props.onOpenInvoice}
        />
      ))}
    </React.Fragment>
  );
}

InvoiceListMobile.propTypes = {
  openInvoices: PropTypes.bool,
};

InvoiceListMobile.defaultProps = {
  openInvoices: false,
};

export default InvoiceListMobile;
