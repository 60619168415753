import styled, { css } from "styled-components";
import { Card as RCard, Row } from "reactstrap";

export const InvoiceRow = styled(Row).attrs({})`
  margin-bottom: 15px;
`;

export const InvoiceRowKey = styled.p`
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const InvoiceRowStatus = styled.p`
  color: ${(props) => props.theme.colors[25]};
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const InvoiceRowValue = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const PDFWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;

export const MoreInfoWrapper = styled.div`
  align-items: center;
  display: flex;

  p {
    margin: 0;
    margin-right: 10px;
  }
`;

export const Card = styled(RCard)`
  margin: 0 0 25px 0;
`;

export const Month = styled.p`
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const ActionHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const PriceTag = styled.span`
  color: ${(props) => props.theme.colors[2]};
  ${(props) =>
    props.status === "Open" &&
    css`
      color: ${props.theme.colors[8]};
    `};
`;
