import React from "react";
import { Container, Row } from "reactstrap";

function Terms() {
  return (
    <Container>
      <Row>
        <b>UVJETI KORIŠTENJA APLIKACIJE „ MOJ VODOVOD – LABIN</b>
      </Row>
      <Row><br></br></Row>
      <Row>1. Osnovne informacije o usluzi „Moj vodovod - Labin“</Row>
      <Row><br></br></Row>
      <Row>
        1.1. "Moj vodovod" aplikacija je usluga (u daljnjem tekstu: Usluga) koja
        Korisnicima omogućava korištenje mobilne i web aplikacije za provjeru
        podataka o računima, potrošnji, pregled povijesti očitanja, dojavu
        stanja, primanje računa i obavijesti u elektroničkom obliku (bez
        papirnate verzije), izravno plaćanje računa te predaju raznih online
        zahtjeva.
      </Row>
      <Row>
        1.2. Usluga je dostupna pravnim i fizičkim osobama koji su Korisnici
        vodnih usluga javnog isporučitelja vodnih usluga Vodovod Labin d.o.o. (u
        daljnjem tekstu: Isporučitelj).
      </Row>
      <Row>
        1.3. Usluga je besplatna te je vlasništvo Isporučitelja. Nadogradnje
        aplikacije su besplatne i služe dodavanju novih funkcionalnosti i
        ispravljanju mogućih grešaka.
      </Row>
      <Row>
        1.4. Pristup Usluzi omogućen je pomoću korisničkog imena i lozinke.
      </Row>
      <Row>
        1.5. Korisnik potvrđuje da su korisničko ime i lozinka koje koristi za
        uslugu, istoznačni njegovom vlastoručnom potpisu, pri pregledu dostupnih
        informacija ili promjeni statusa određenih servisa unutar Usluge.
      </Row>
      <Row>
        1.6. Korisnik je dužan čuvati svoje korisničko ime i lozinku u svrhu
        zaštite od njihovog neovlaštenog korištenja, odnosno neovlaštenog
        korištenja Usluge.
      </Row>
      <Row>
        1.7. Isporučitelj ne preuzima odgovornost u slučaju neovlaštenog
        korištenja korisničkog imena i lozinke te za bilo kakvu štetu nastalu
        eventualnim neovlaštenim korištenjem Usluge.
      </Row>
      <Row>
        1.8. Usluga omogućava Korisnicima primanje računa isključivo u
        elektroničkom obliku. Uslugu nije moguće otkazati u aplikaciji "Moj
        vodovod - Labin". Za otkazivanje usluge potrebno je kontaktirati
        Isporučitelja (Potrošački odjel) uobičajenim kanalima komunikacije
        (osobno, telefonski ili elektroničkom poštom na adresu
        vodovod-labin@vodovod-labin.hr).
      </Row>
      <Row><br></br></Row>
      <Row>2. Korištenje Usluge</Row>
      <Row><br></br></Row>
      <Row>
        2.1. Korištenje Usluge započinje registracijom u aplikaciji "Moj vodovod
        - Labin".
      </Row>
      <Row>
        2.2. Vodovod Labin d.o.o. u svrhu unapređenja Usluge povremeno će
        ažurirati aplikaciju "Moj vodovod - Labin".
      </Row>
      <Row>
        2.3. Odgovornost je Korisnika ažurirati aplikaciju novim verzijama kako
        bi imao aplikaciju s ispravljenim eventualnim pogreškama, i aplikaciju
        nadograđenu novim funkcionalnostima i poboljšanjima.
      </Row>
      <Row>
        2.4. Podaci i usluge kojima se pristupa koristeći uslugu možda neće biti
        dostupni u svakom trenutku. Korisnik neće smatrati Isporučitelja
        odgovornim za bilo kakve greške u radu aplikacije ili eventualno
        kašnjenje u prijenosu podataka.
      </Row>
      <Row>
        2.5. Vodovod Labin d.o.o. isključuje svaku odgovornost za bilo kakav
        gubitak funkcionalnosti usluga i kvalitete sadržaja na Vodovod Labin
        d.o.o. korisničkim stranicama u svim slučajevima odabira uređenja
        primanja kolačića od strane Korisnika.
      </Row>
      <Row>
        2.6. Preuzimanjem i korištenjem Usluge, Korisnik prihvaća ove Uvjete
        korištenja.
      </Row>
      <Row><br></br></Row>
      <Row>3. Zaštita osobnih podataka (GDPR)</Row>
      <Row><br></br></Row>
      <Row>
        Vodovod Labin d.o.o. kao voditelj obrade osobnih podataka jamči
        povjerljivost Vaših osobnih podataka koji će se prikupljati i obrađivati
        isključivo u opsegu koji je nužan za ostvarivanje Vaših prava i obveza
        vezanih za usluge vodoopskrbe i odvodnje. Vodovod Labin d.o.o. kao
        voditelj obrade će Vaše osobne podatke na odgovarajući način zaštiti od
        uništenja, gubitka, neovlaštenih promjena, dostupa i svake zlouporabe
        sukladno Općoj uredbi o zaštiti osobnih podataka (SL EU L 119/1), Zakonu
        o provedbi opće uredbe o zaštiti podataka (NN 42/2018) i Pravilniku o
        prikupljanju, obradi i korištenju te zaštiti osobnih podataka fizičkih
        osoba Vodovoda Labin d.o.o.
      </Row>
      <Row><br></br></Row>
      <Row>4. Pravo izmjene</Row>
      <Row><br></br></Row>
      <Row>
        Vodovod Labin d.o.o. zadržava pravo izmjene ovih Uvjeta korištenja, o
        čemu će Korisnici biti obaviješteni putem službene web stranice
        www.vodovod-labin.hr ili na drugi primjeren način.
      </Row>
      <Row><br></br></Row>
      <Row><br></br></Row>
      <Row>
        Ovi Uvjeti korištenja primjenjuju se od 29. Lipanj, 2023. godine.
      </Row>
    </Container>
  );
}

export default Terms;
