import styled from "styled-components";

export const Nav = styled.nav`
  background-color: ${(props) => props.theme.colors[0]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 25px 0 0 0;
  overflow-y: auto;
  border-top: 1px solid ${(props) => props.theme.colors[17]};

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      &.mobileOnly {
        @media (min-width: 1024px) {
          display: none;
        }
      }

      a {
        display: block;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: ${(props) => props.theme.colors[18]};
        text-decoration: none;
        padding: 10px 25px;
        transition: 0.2s color ease-in;

        &.divider {
          border-top: 1px solid ${(props) => props.theme.colors[5]};
        }

        span {
          margin-left: 17px;
          transition: 0.1s font-weight ease-in;
        }

        &:hover,
        &.active {
          color: ${(props) => props.theme.colors[19]};

          span {
            font-weight: 600;
          }

          svg {
            path {
              fill: ${(props) => props.theme.colors[19]};
            }
          }
        }
      }

      svg {
        path {
          transition: 0.2s all ease-in;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const LinkItem = styled.div`
  align-items: center;
  display: flex;
`;

export const IconWrapper = styled.div`
  width: 25px;
`;

export const Author = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors[18]};
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;

  a {
    color: ${(props) => props.theme.colors[18]};
    text-decoration: underline;
  }
`;
