import React from "react";
import Styled from "./RequestWidget.styles";

const RequestWidget = ({ item, onClick }) => {
  return (
    <Styled.Widget size="small" onClick={() => onClick(item)}>
      <Styled.Content>
        <Styled.Title dangerouslySetInnerHTML={{ __html: item.name }} />
      </Styled.Content>
      <Styled.Spacer />
      <Styled.Footer>
        <Styled.Icon />
      </Styled.Footer>
    </Styled.Widget>
  );
};

export default RequestWidget;
