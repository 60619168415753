import { call, fork, takeLatest, put, select } from "redux-saga/effects";
import { logoutUser } from "../actions/user/user.actions";
import * as actions from "../actions/payAll.actions";
import { payAllSignature } from "../api/invoice.api";

function* getPayAllSignature({ payload }) {
  try {
    const state = yield select();
    const response = yield call(payAllSignature, {
      userId: state.user.userData.data.userId,
      invoiceIds: payload.invoiceIds,
    });
    yield put(actions.getPayAllSignatureSuccess(response.data));
  } catch (e) {
    if (e.response.status === 401) yield put(logoutUser());
    yield put(
      actions.getPayAllSignatureError({
        error: "An error occurred when trying to get signature",
      })
    );
  }
}

function* watchGetPayAllSignature() {
  yield takeLatest(actions.Types.GET_PAYALL_SIGNATURE, getPayAllSignature);
}

export default [fork(watchGetPayAllSignature)];
