import styled from "styled-components";

export const Profile = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 100%;

  svg {
    margin: 0 2px 0 0;
  }

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;
    color: ${(props) => props.theme.colors[18]};
  }
`;
