import React from "react";
import { PushSpinner } from "react-spinners-kit";
import * as Styled from "./Loader.styles";

export default function Loader({
  inline = false,
  color = "#686769",
  size = 30
}) {
  return (
    <Styled.Spinner inline={inline}>
      <PushSpinner size={size} color={color} />
    </Styled.Spinner>
  );
}
