import styled from "styled-components";

export default {
  Widget: styled.div`
    background: ${(props) => props.theme.colors[0]};
    box-shadow: 0 6px 18px 0 ${(props) => props.theme.colors[16]};
    border-radius: 4px;
    padding: 13px 10px;
    min-height: 100px;
    margin: 0 0 20px;
    height: calc(100% - 20px);

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: ${(props) => props.theme.colors[23]};
      margin: 0 0 5px;
    }
  `,

  WidgetNumber: styled.div`
    color: ${(props) =>
      props.negative ? props.theme.colors[25] : props.theme.colors[26]};
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 22px;
    text-align: center;
    margin: 10px 0;
  `,

  WidgetDescription: styled.p`
    color: ${(props) => props.theme.colors[18]};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 19px;
    margin: 0;
    opacity: 0.5;
    text-align: right;
  `,
};
