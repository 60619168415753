import axios from "axios";

export const getDocumentTypes = () => {
  return axios.get(`/documents`);
};

export const getDocumentPdf = (userId, { documentId, fields }) => {
  return axios.post(`/documents/${documentId}/${userId}`, fields, {
    responseType: "blob",
  });
};

export const getDocumentPdfFields = (
  userId,
  { documentId, meterSiteCode, meterCode }
) => {
  return axios.post(`/documents/${documentId}/${userId}/fields`, {
    meterSiteCode,
    meterCode,
  });
};

export const getDocumentPdfFieldsUpdated = (
  userId,
  { documentId, meterSiteCode, meterCode }
) => {
  return axios.post(`/documents/${documentId}/${userId}/updatedFields`, {
    meterSiteCode,
    meterCode,
  });
};
