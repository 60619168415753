import React from "react";
import { GridSpinner } from "react-spinners-kit";
import * as Styled from "./InvoicePdf.styles";
import { IconPdf } from "../../../Icons/Icons";

export default function InvoicePdf(props) {
  if (props.downloading) {
    return <GridSpinner size={29} color="#686769" />;
  } else {
    return (
      <Styled.DownloadLink onClick={props.handleOpenInvoice}>
        <IconPdf />
      </Styled.DownloadLink>
    );
  }
}
